import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import PageContent from "../../../layout/PageContent";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { IconFileReport } from "../../../ui/Icon/Line/FileReport";
import { IconTitle } from "../../../ui/IconTitle/IconTitle";
import { PageCounter } from "../../../ui/Table/PageCounter";
import { Table } from "../../../ui/Table/Table";
import { getQueryString } from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  getPresetsAsync,
  Preset,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import UserContext from "../../users/userContext";
import {
  getAllScheduledReportAsync,
  ReportArchive,
  reportsArchiveSelectors,
  selectReportArchiveStatusSlicePage,
  selectReportArchiveStatusSliceStatus,
  selectReportArchiveStatusSlicTotalElements,
} from "./reportArchiveSlice";
import { ReportArchiveTableBuilder } from "./ReportArchiveTableBuilder";
import { ReportsArchiveFilterBar } from "./ReportsArchiveFilterBar";
import "./ReportsArchiveFilterBar.css";
import { ReportsArchiveModal } from "./ReportsArchiveModal";
import "./ReportsArchiveView.css";

interface ReportsArchiveViewProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

interface CheckedProps {
  id: number;
  color: string;
  number: number;
  vehicleId: number;
  queryParams: string;
}

export const ReportsArchiveView: React.FC<ReportsArchiveViewProps> = ({
  permissions,
}) => {
  const navigate = useNavigate();

  const reportsArchive: ReportArchive[] = _.orderBy(
    useAppSelector(reportsArchiveSelectors.selectAll),
    "lastScheduledReportExecution.executionTime",
    "desc"
  );

  const tableBuilderRef = useRef<ReportArchiveTableBuilder>(
    new ReportArchiveTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const [resetPage, setResetPage] = useState<boolean>(false);

  const tableData: TableData = {} as TableData;

  const context = "reportArchive";

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  const presetsArchiveSliceStatus = useAppSelector(selectpresetsSliceStatus);

  const [checkedList, setCheckedList] = useState<CheckedProps[]>([]);

  const [page, setPage] = useState(0);

  const archiveRoute = useMatch("reports/schedulation/archive/:executionsId");

  const archiveSlicePages = useAppSelector(selectReportArchiveStatusSlicePage);

  const archiveSliceTotalElements = useAppSelector(
    selectReportArchiveStatusSlicTotalElements
  );

  const reportArchiveSliceStatus = useAppSelector(
    selectReportArchiveStatusSliceStatus
  );

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");

  useEffect(() => {
    if (!window.location.search) {
      const pageAndSize = getQueryString({
        page: page,
        size: "10",
        sort: "scheduledReportExecutions.executionTime,DESC",
      });
      store.dispatch(
        getAllScheduledReportAsync({
          queryParams: pageAndSize,
        })
      );
    }
    store.dispatch(getPresetsAsync(getQueryString({ context: context })));
    setResetPage(!resetPage);
  }, []);

  if (presetsArchiveSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element) => {
            return {
              name: element,
            };
          })
        : []
    );

    tableData.rows =
      presetsArchiveSliceStatus === "idle" &&
      tableData.columns &&
      tableData?.columns?.length > 0
        ? reportsArchive?.map((reportArchive: ReportArchive) => {
            return tableBuilder.rowsBuilder(
              tableData.columns,

              {
                reportArchive: reportArchive,
              },
              navigate,
              preferencesContext
            );
          })
        : tableData.rows;
  }

  return (
    <>
      <div>
        <ReportsArchiveFilterBar
          types={Array.from(
            new Set(reportsArchive.map((report) => report.type))
          )}
          filter={(params) => {
            setQueryParamsFromFilterBar(params);
            const pageAndSize = getQueryString({
              page: page,
              size: "10",
              sort: "scheduledReportExecutions.executionTime,DESC",
            });
            const finalQueryParams = params ? params : pageAndSize;

            store.dispatch(
              getAllScheduledReportAsync({
                queryParams:
                  finalQueryParams +
                  "&sort=scheduledReportExecutions.executionTime,DESC",
              })
            );

            setResetPage(!resetPage);
          }}
        />
        {tableData.rows?.length > 0 ? (
          <div style={{ display: "inline-flex" }}>
            <div className="ior-table-container">
              <div style={{ display: "inline-flex", width: "100%" }}>
                <div className="ior-checkbox-list"></div>
                <div className="archivereport-rows-container">
                  <Table data={tableData}>
                    <Table.Head />
                    <Tooltip />
                    <Table.Body id="ior-table-body" />
                  </Table>
                </div>
                <div className="ior-marker-list"></div>
              </div>
              <div>
                <PageCounter
                  isActionPerforming={reportArchiveSliceStatus === "loading"}
                  totalElements={archiveSliceTotalElements}
                  numOfPages={archiveSlicePages}
                  numSelected={checkedList.length}
                  resetPage={resetPage}
                  onClick={(id, currentPage) => {
                    if (id !== currentPage) {
                      setPage(id - 1);
                      const pageAndSize = getQueryString({
                        page: id - 1,
                        size: "10",
                        sort: "scheduledReportExecutions.executionTime,DESC",
                      });
                      const finalQueryParams = queryParamsFromFilterBar
                        ? queryParamsFromFilterBar +
                          pageAndSize.replace("?", "&")
                        : pageAndSize;
                      store.dispatch(
                        getAllScheduledReportAsync({
                          queryParams: finalQueryParams,
                        })
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          reportArchiveSliceStatus !== "loading" && (
            <PageContent>
              <div
                className="file-not-found"
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "var(--global-colors-sky-lighter)",
                }}
              >
                <IconTitle
                  icon={<IconFileReport size={48} color="#031832" />}
                  title={t("report.archive.reportNotFound")}
                  text={""}
                />
              </div>
            </PageContent>
          )
        )}
        <ReportsArchiveModal
          name={
            reportsArchive.find(
              (e) =>
                e.id === parseInt(archiveRoute?.params?.executionsId ?? "0")
            )?.name ?? ""
          }
          open={archiveRoute?.params?.executionsId !== undefined}
          onClose={() => navigate(`/reports/schedulation/archive/`)}
        />
      </div>
    </>
  );
};
