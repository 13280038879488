import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Button } from "../../../ui/Button/Button";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import {
  getQueryString,
  getReportRouteNameTranslation,
} from "../../../utils/Utils";
import { selectReportArchiveExecutionsStatusSliceStatus } from "./reportArchiveExecutionsSlice";
import {
  getAllScheduledReportAsync,
  selectReportArchiveStatusSliceStatus,
} from "./reportArchiveSlice";

interface ReportsArchiveFilterBarProps {
  types: any;
  filter: (params: any) => any;
}

interface QueryParams {
  [paramName: string]: any;
}

export const ReportsArchiveFilterBar: React.FC<ReportsArchiveFilterBarProps> =
  ({ types, filter }) => {
    const [searchParams] = useSearchParams();
    const [schedulationName, setSchedulationName] = useState("");
    const [generationDate, setGenerationDate] = useState<string[]>(["", ""]);
    const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);
    const [reportType, setReportType] = useState<any>({
      value: "ALL_REPORT",
      label: t("report.archive.allReports"),
    });

    const [reportStatus, setReportStatus] = useState<any>({
      value: "ALL_STATUS",
      label: t("report.archive.allStatuses"),
    });

    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;
    const navigate = useNavigate();
    const [stringifiedQueryString, setStringifiedQueryString] = useState("");

    const archiveSliceStatus = useAppSelector(
      selectReportArchiveStatusSliceStatus
    );

    const archiveExecutionSliceStatus = useAppSelector(
      selectReportArchiveExecutionsStatusSliceStatus
    );

    useEffect(() => {
      const currentSearchParams =
        searchParams.toString() !== "" ? searchParams : null;
      let dates = [] as Date[];
      const startPeriod = currentSearchParams?.get("startPeriod");
      const endPeriod = currentSearchParams?.get("endPeriod");
      const initialNameParam = currentSearchParams?.get("nameSchedulation");
      const initialTypeParam = currentSearchParams?.get("type");
      if (startPeriod && endPeriod) {
        dates.push(new Date(startPeriod));
        dates.push(new Date(endPeriod));
        setInitialDateValue(dates);
      }
      initialNameParam && setSchedulationName(initialNameParam);
      initialTypeParam &&
        setReportType({
          id: initialTypeParam,
          label:
            reportElements[initialTypeParam as keyof typeof reportElements],
        });

      setStringifiedQueryString(searchParams.toString());
      if (searchParams.toString() !== "") {
        filter("?" + searchParams.toString());
        store.dispatch(
          getAllScheduledReportAsync({
            queryParams: "?" + searchParams.toString(),
          })
        );
      }
    }, []);

    //#region Type
    const reportStatusValues = {
      ALL_REPORT: t("report.archive.allStatuses"),
      FAILED: t("table.columns.reportStatus.FAILED"),
      EXECUTED: t("table.columns.reportStatus.EXECUTED"),
      RUNNING: t("table.columns.reportStatus.RUNNING"),
    } as const;

    const reportElements = {
      ALL_REPORT: t("report.archive.allReports"),
      VEHICLE_TRAVEL_REPORT: getReportRouteNameTranslation(
        "VEHICLE_TRAVEL_REPORT"
      ),
      VEHICLE_STOP_REPORT: getReportRouteNameTranslation("VEHICLE_STOP_REPORT"),
      DRIVER_TRAVEL_REPORT: getReportRouteNameTranslation(
        "DRIVER_TRAVEL_REPORT"
      ),
      GPSDATA_ROUTE_REPORT: getReportRouteNameTranslation(
        "GPSDATA_ROUTE_REPORT"
      ),
      GPSDATA_STOP_REPORT: getReportRouteNameTranslation("GPSDATA_STOP_REPORT"),
      GEOFENCE_ROUTE_REPORT: getReportRouteNameTranslation(
        "GEOFENCE_ROUTE_REPORT"
      ),
      GEOFENCE_STOP_REPORT: getReportRouteNameTranslation(
        "GEOFENCE_STOP_REPORT"
      ),
      GEOFENCE_ROUTE_AND_STOP_REPORT: getReportRouteNameTranslation(
        "GEOFENCE_ROUTE_AND_STOP_REPORT"
      ),
      EVENTS_IO_REPORT: getReportRouteNameTranslation("EVENTS_IO_REPORT"),
      UTILIZATION_REPORT: getReportRouteNameTranslation("UTILIZATION_REPORT"),
      REFUELLING_REPORT: getReportRouteNameTranslation("REFUELLING_REPORT"),
      LOGS_REPORT: getReportRouteNameTranslation("LOGS_REPORT"),
      VEHICLE_ACTIVITY_REPORT: getReportRouteNameTranslation(
        "VEHICLE_ACTIVITY_REPORT"
      ),
      DAILY_ACTIVITY_REPORT: getReportRouteNameTranslation(
        "DAILY_ACTIVITY_REPORT"
      ),
      INFRINGEMENT_REPORT: getReportRouteNameTranslation("INFRINGEMENT_REPORT"),
    } as const;

    const reportTypes = Object.keys(reportElements).map((key) => ({
      id: key,
      label: reportElements[key as keyof typeof reportElements],
    }));

    const reportStatuses = Object.keys(reportStatusValues).map((key) => ({
      id: key,
      label: reportStatusValues[key as keyof typeof reportStatusValues],
    }));

    const handleChanges = (
      params: Map<string, string[] | string>,
      generateImmediatly?: boolean
    ): void => {
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value) {
            queryParams[key] = value;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
            }
          }
        });

        queryParams["endPeriod"] == null && delete queryParams["startPeriod"];
        queryParams["type"] == "ALL_REPORT" && delete queryParams["type"];
        queryParams["scheduledReportExecutions.reportExecutionStatusEnum"] ==
          "ALL_STATUS" &&
          delete queryParams[
            "scheduledReportExecutions.reportExecutionStatusEnum"
          ];

        setStringifiedQueryString(getQueryString(queryParams));
        navigate("/reports/schedulation/archive" + getQueryString(queryParams));
      }
    };

    return (
      <>
        <div className="report-archive-filterbar">
          {
            <Form>
              <SearchField
                name="search"
                id="search-field"
                size="small"
                placeholder={t("report.archive.schedulationName")}
                value={schedulationName}
                onChange={(val) => {
                  setSchedulationName(val);
                  const map = new Map();
                  map.set("nameSchedulation", val);
                  handleChanges(map);
                }}
              />
            </Form>
          }
          <Form>
            <DatePickerDropdown
              icon={<IconCalendar size={14} color="#687484" />}
              placeholder={t("report.archive.generationDate")}
              initialValue={initialDateValue}
              setDate={(val) => {
                const period = [];
                if (val[0]) {
                  period.push(val[0].format("YYYY/MM/DD"));
                }
                if (val[1]) {
                  period.push(val[1].format("YYYY/MM/DD"));
                }
                setGenerationDate(period);

                const map = new Map();
                const startPeriod =
                  typeof val[0] === "string"
                    ? val[0]
                    : val[0].format("YYYY-MM-DDTHH:mm");
                map.set("startPeriod", startPeriod);
                map.set("endPeriod", val[1]?.format("YYYY-MM-DDTHH:mm") ?? "");

                if (map.get("endPeriod") === "") {
                  map.delete("startPeriod");
                  map.delete("endPeriod");
                  delete queryParams["startPeriod"];
                  delete queryParams["endPeriod"];
                }

                handleChanges(map);
              }}
            />
          </Form>
          {
            <div style={{ width: "fit-content" }}>
              <Dropdown
                value={reportType}
                isClearable={false}
                itemAttribute="label"
                placeholder={t("admin.driver.editViewCreate.licenseType")}
                size="small"
                onChange={(val) => {
                  setReportType(val);
                  const map = new Map();

                  map.set("type", val[0].id);
                  handleChanges(map);
                }}
                options={reportTypes}
              />
            </div>
          }
          {
            <div style={{ width: "fit-content" }}>
              <Dropdown
                value={reportStatus}
                isClearable={false}
                itemAttribute="label"
                placeholder={t("admin.driver.editViewCreate.licenseType")}
                size="small"
                onChange={(val) => {
                  setReportStatus(val);
                  const map = new Map();

                  map.set(
                    "scheduledReportExecutions.reportExecutionStatusEnum",
                    val[0].id
                  );
                  handleChanges(map);
                }}
                options={reportStatuses}
              />
            </div>
          }

          <Button
            isLoading={
              archiveSliceStatus === "loading" ||
              archiveExecutionSliceStatus === "loading"
            }
            aspect="primary"
            size={"small"}
            label={t("common.search")}
            onClick={() => filter(stringifiedQueryString)}
          />
        </div>
      </>
    );
  };
