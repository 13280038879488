import dayjs from "dayjs";
import i18next from "i18next";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker } from "./DatePicker";
import { DatePickerProvider } from "./DatePickerContext";
import "./DatePickerDropdown.css";
import { isValidDate } from "./utils/dateConversion";
export const DatePickerDropdown = ({
  setDate,
  localeFormat,
  language,
  icon,
  defaultValue,
  initialValue,
  isDouble,
  hasDatePresets,
  weekPresets,
  isSingleDate = false,
  hasComparison,
  hasTime,
  dateRange,
  limitDaysRange,
  placeholder,
  clearCallback,
  styledTransparent,
  getSelectedPeriodOrDate,
  open,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("");
  const [compareValue, setCompareValue] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [rangeSelected, setRangeSelected] = React.useState(
    initialValue?.map((date) => dayjs(date)) ?? []
  );
  const [compareRangeSelected, setCompareRangeSelected] = React.useState([]);
  const inputRef = React.useRef(null);
  const pickerRef = React.useRef(null);

  dayjs.locale(language || "en");

  /**



   * When an initial value is provided (an array of max two dates) a new date or range of date



   * is selected and a new placeholder string is set.



   */

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (initialValue && initialValue.length > 0) {
      const dayJSInitialValue = initialValue.map((date) => dayjs(date));

      const startInitialValue = dayJSInitialValue[0];
      let endInitialValue = dayJSInitialValue[1];

      if (limitDaysRange && startInitialValue && endInitialValue) {
        if (startInitialValue.diff(endInitialValue, "day") >= limitDaysRange)
          endInitialValue = startInitialValue.subtract(
            limitDaysRange - 1,
            "days"
          );
        if (startInitialValue.diff(endInitialValue, "day") <= -limitDaysRange)
          endInitialValue = startInitialValue.add(limitDaysRange - 1, "days");
      }
      const newDayJSInitialValue = [];
      if (startInitialValue) {
        newDayJSInitialValue.push(startInitialValue);
      }
      if (endInitialValue) {
        newDayJSInitialValue.push(endInitialValue);
      }
      setRangeSelected(newDayJSInitialValue);
      const placeholder = newDayJSInitialValue
        ? newDayJSInitialValue
            .map((date) =>
              date.format(
                `DD MMM YYYY${
                  hasTime && initialValue.length > 1 ? " HH:mm" : ""
                }`
              )
            )
            .join(" - ")
        : "";
      setValue(placeholder);
    }
  }, [initialValue]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        pickerRef.current &&
        !pickerRef.current.contains(event.target)
      )
        setIsOpen(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  function onSubmit(selectedDates, preset) {
    setIsOpen(false);
    if (isSingleDate) {
      let returnDates = [];
      returnDates.push(selectedDates);
      setDate(returnDates);
      return;
    }
    setRangeSelected([selectedDates[0], selectedDates[1], preset]);
    setCompareRangeSelected([
      selectedDates[2],
      selectedDates[3],
      selectedDates[4],
    ]);
    let startDateTime = "",
      endDateTime = "";
    let isSameDates =
      selectedDates[0]?.format("DD MMM YYYY HH:mm") ===
      selectedDates[1]?.format("DD MMM YYYY HH:mm");
    if (hasTime && selectedDates[1] && !isSameDates) {
      startDateTime = selectedDates[0]?.format(" HH:mm");
      endDateTime = selectedDates[1]?.format(" HH:mm");
    }
    let startDate = selectedDates[0]?.format("DD MMM YYYY") + startDateTime;
    let endDate = selectedDates[1]?.format("DD MMM YYYY") + endDateTime;
    let secondFirstDate = selectedDates[2]?.format("DD MMM YYYY");
    let secondEndDate = selectedDates[3]?.format("DD MMM YYYY");
    let datePreset = preset && !selectedDates[2] ? preset + ": " : "";

    if (startDate === endDate || selectedDates[1] === null)
      setValue(datePreset + startDate);
    else setValue(datePreset + startDate + " - " + endDate);
    if (selectedDates[2])
      if (secondFirstDate === secondEndDate || selectedDates[3] === null)
        setCompareValue(datePreset + secondFirstDate);
      else setCompareValue("vs " + secondFirstDate + " - " + secondEndDate);

    let returnDates = [];
    selectedDates.forEach((date) => {
      if (dayjs(date).isValid()) returnDates.push(date);
    });
    setDate(returnDates);
  }

  function calculateWidth() {
    if (value.length === 0) return "fit-content";
    else return value.length + 16 + "ch";
  }

  function setStartDate() {
    if (isValidDate(rangeSelected[0])) return rangeSelected[0].toDate();
    if (initialValue && isValidDate(dayjs(initialValue[0])))
      return initialValue[0];
    return defaultValue;
  }

  return (
    <React.Fragment>
      <div
        ref={inputRef}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          width: calculateWidth(),
          border: styledTransparent ? "none" : undefined,
        }}
        className={
          isOpen || value !== "" || defaultValue
            ? "datepicker-dropdown-btn active"
            : "datepicker-dropdown-btn"
        }
      >
        <span>
          <input
            type="text"
            readOnly
            placeholder={
              defaultValue
                ? dayjs(defaultValue)?.format("DD MMM YYYY")
                : !!placeholder
                ? placeholder
                : t("common.datePicker.dropdownLabel")
            }
            value={value}
            className={compareValue.length > 0 ? "first-compare-date" : ""}
          />
          {compareValue.length > 0 && (
            <input
              type="text"
              readOnly
              className="second-compare-date"
              value={compareValue}
            />
          )}
        </span>
        <div
          className={
            isOpen && !icon ? "dp-dropdown-arrow open" : " dp-dropdown-arrow"
          }
        >
          {icon}
        </div>
      </div>
      {isOpen && (
        <div
          className="datepicker-dropdown-container"
          style={{ right: styledTransparent ? "21px" : "initial" }}
          ref={pickerRef}
        >
          <DatePickerProvider
            dateRange={dateRange}
            startDate={setStartDate()}
            hasDatePresets={hasDatePresets}
            endDate={
              isValidDate(rangeSelected[1])
                ? rangeSelected[1].toDate()
                : initialValue &&
                  isValidDate(dayjs(initialValue[1])) &&
                  initialValue[1]
            }
            secondStartDate={
              isValidDate(compareRangeSelected[0]) &&
              compareRangeSelected[0].toDate()
            }
            secondEndDate={
              isValidDate(compareRangeSelected[1]) &&
              compareRangeSelected[1].toDate()
            }
            preset={rangeSelected[2]}
            toggleComparison={compareRangeSelected[0]}
            activeComparison={compareRangeSelected[2]}
            language={language || i18next.language}
            limitDaysRange={limitDaysRange}
          >
            <DatePicker
              isDouble={isDouble}
              isSingleDate={isSingleDate}
              hasDatePresets={hasDatePresets}
              hasComparison={hasComparison}
              hasTime={hasTime}
              weekPresets={weekPresets}
              handleSubmit={onSubmit}
              resetCallback={() => {
                setValue("");
                setCompareValue("");
                setRangeSelected([]);
                setCompareRangeSelected([]);
                setDate([dayjs(defaultValue)]);
                clearCallback && clearCallback();
              }}
              localeFormat={localeFormat || "DD / MM / YYYY"}
              getSelectedPeriodOrDate={(period) => {
                getSelectedPeriodOrDate && getSelectedPeriodOrDate(period);
              }}
            />
          </DatePickerProvider>
        </div>
      )}
    </React.Fragment>
  );
};

DatePickerDropdown.propTypes = {
  setDate: PropTypes.func,
  localeFormat: PropTypes.string,
  isSingleDate: PropTypes.bool,
  language: PropTypes.string,
  isDouble: PropTypes.bool,
  hasDatePresets: PropTypes.bool,
  hasComparison: PropTypes.bool,
  styledTransparent: PropTypes.bool,
  hasTime: PropTypes.bool,
  placeholder: PropTypes.string,
  dateRange: PropTypes.array,
  initialValue: PropTypes.array,
  limitDaysRange: PropTypes.number,
};
