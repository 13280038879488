import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { t } from "i18next";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PublicStopETA,
  PublicStopStatus,
  PublicTransportStatus,
} from "../../features/publicTransport/status/publicTransportDocumentStatusSlice";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import UserContext from "../../features/users/userContext.js";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import {
  formatData,
  formatMinutes,
  getIconFromVehicleType,
  getQueryString,
  kmPerHToMilesPerH,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils.js";
import { Button } from "../Button/Button";
import { DataLabel } from "../DataLabel/DataLabel";
import { Tooltip } from "../Forms/Tooltip";
import { IconAlarmSiren } from "../Icon/Line/AlarmSiren";
import { IconAutomations } from "../Icon/Line/Automations";
import { IconDevices } from "../Icon/Line/Devices";
import { IconExchange } from "../Icon/Line/Exchange";
import { IconGasStation } from "../Icon/Line/GasStation";
import { IconLayers } from "../Icon/Line/Layers";
import { IconLicense } from "../Icon/Line/License";
import { IconList } from "../Icon/Line/List";
import { IconLocationHistory } from "../Icon/Line/LocationHistory";
import { IconMenuRoutes } from "../Icon/Line/MenuRoutes";
import { IconMessages } from "../Icon/Line/Messages";
import { IconOdometer } from "../Icon/Line/Odometer";
import { IconTachometer } from "../Icon/Line/Tachometer";
import { IconTemperature } from "../Icon/Line/Temperature";
import { IconTrailerTruck } from "../Icon/Line/TrailerTruck";
import { IconUp } from "../Icon/Line/Up";
import { IconUtilization } from "../Icon/Line/Utilization";
import { IconWarning } from "../Icon/Line/Warning";
import { IconBattery } from "../Icon/Solid/Battery";
import { ExternalBattery } from "../Icon/Solid/ExternalBattery";
import { IconFlag } from "../Icon/Solid/Flag";
import { IconKey } from "../Icon/Solid/Key";
import { IconLocator } from "../Icon/Solid/Locator";
import { Signal } from "../Indicators/IndicatorSignal";
import { ThumbProfile } from "../ThumbProfile/ThumbProfile";
import { SignalIndicator } from "./SignalIndicator";
import "./VehicleDetailsModal.css";

dayjs.extend(duration);

export type FleetView = {
  id: number;
  name: string;
};

export const typeOfVehicleValues = {
  CAR: "CAR",
  TRUCK: "TRUCK",
  AMBULANCE: "AMBULANCE",
  AUTOBUS: "AUTOBUS",
  BIKE: "BIKE",
  AIRPLANE: "AIRPLANE",
  CAMPER: "CAMPER",
  BOAT: "BOAT",
  SCOOTER: "SCOOTER",
  OPERATING_MACHINE: "OPERATING_MACHINE",
  HUMAN_BEING: "HUMAN_BEING",
};

export type TypeOfVehicleType = keyof typeof typeOfVehicleValues;

export interface Vehicle {
  id: number;
  alias: string;
  brand: string;
  plate: string;
  registrationDate: string;
  model: string;
  unitOfMeasurement: VehicleUnitOfMeasurementType;
  fuelType: VehicleFuelType;
  consumption: number;
  emissions: number;
  maxSpeed: number | string;
  fuelPrice: number | string;
  device: number;
  contract: number;
  name: string;
  fuelCapacity: number;
  imageUrl: string;
  initialKms: number;
  type: TypeOfVehicleType;
  status: VehicleStatusType;
  driver: number;
  tenant: number;
  fleet: number;
  vehicleStatus: number;
  city: string;
  maintenance: boolean;
  driverIdentifiedBy: AuthenticationType;
}

export const vehicleStatusValues = { ACTIVE: "ACTIVE", INACTIVE: "INACTIVE" };
export type VehicleStatusType = keyof typeof vehicleStatusValues;

export const authenticationType = {
  MOBILE: "MOBILE",
  WEB: "WEB",
  BEACON: "BEACON",
  KEYPAD: "KEYPAD",
};
export type AuthenticationType = keyof typeof authenticationType;

export const vehicleUnitOfMeasurementValues = { KM: "KM", MILES: "MILES" };
export type VehicleUnitOfMeasurementType =
  keyof typeof vehicleUnitOfMeasurementValues;

export const vehicleFuelValues = {
  PETROL: "PETROL",
  DIESEL: "DIESEL",
  ELETTRICALLY_CHARGEABLE: "ELETTRICALLY_CHARGEABLE",
  HYBRID_ELECTRIC: "HYBRID_ELECTRIC",
  ALTERNATIVE_FUELS: "ALTERNATIVE_FUELS",
};
export type VehicleFuelType = keyof typeof vehicleFuelValues;

export interface Asset {
  id: number;
  name: string;
  decription: string;
  imageUrl: string;
  sku: string;
}

export interface VehicleStatus {
  id: number;
  deviceStatus: DeviceStatusType;
  dynamicFields?: {
    customerId: number;
    deviceId: number;
    direction: number;
    gpsPositions: GPSPositions[];
    ignitionKey: boolean;
    lastUpdate: Date;
    latitude: number;
    longitude: number;
    odometer: number;
    timestamp_t3: Date;
    timestamp_t2: Date;
    timestamp: Date;
    siren: boolean;
    speed: number;
    engineLock: boolean;
    address: string;
    fuelLevel: number;
    fuelLevelLiters: number;
    door: boolean;
    trailerTruck: boolean;
    temperature: number;
    vehicleStatus: StatusVehicleType;
    externalBatteryLevel: number;
    internalBatteryLevel: number;
    dataSource: string;
    fix: boolean;
    hdop: number;
    pdop: number;
    gsm: number;
    satellites: number;
    utilizationMinutes: number;
  };
}

export type GPSPositions = {
  latitude: number;
  longitude: number;
  gpsPositionTimestamp: string;
  address: string;
};

export const driverStatusValues = { ACTIVE: "ACTIVE", INACTIVE: "INACTIVE" };
export type DriverStatusType = keyof typeof driverStatusValues;

export type Driver = {
  id: number;
  username: string;
  firstName: string;
  address: string;
  zip: string;
  avatar: string;
  city: string;
  dateOfBirth: string;
  email: string;
  fiscalCode: string;
  fleet: number;
  lastName: string;
  licenseExpirationDate: string;
  licenseId: string;
  licenseType: string;
  phoneNumber: string;
  phoneNumberPrefix: string;
  linkedToAccount: boolean;
  status: DriverStatusType;
  tachographCard: string;
  tenant: number;
  vehicle: number;
};

export type DriverStatus = {
  id: number;
  driverStatus: string;
  dynamicFields: {
    drivingBar: number;
    odometer: number;
    todayHours: number;
    currentPosition: string;
    drivingSince: number;
    tomorrowHours: number;
    available: number;
    kmTraveled: number;
    totalToday: number;
    slot: string;
    breakBar: number;
    breakTime: number;
    lastUpdate: Date;
    ignitionKey: boolean;
    vehicleId: number;
    availableBar: number;
    multipleVehicle: boolean;
    vehicleDetails: number;
  };
};

export type DeviceStatusType = "ONLINE" | "OFFLINE" | "NO_SIGNAL" | "UNKNOWN";

export type StatusVehicleType =
  | "MOVING"
  | "STOP"
  | "PARKING"
  | "UNKNOWN"
  | "OFFLINE";

const VehicleDetailsModal = ({ ...props }) => {
  return <div className="mn-vehicle-details">{props.children}</div>;
};

interface HeaderProps {
  signalState: StatusVehicleType;
  vehicleId: string | number;
  vehicleLicensePlate: string;
  icon?: object;
}

const Header: React.FC<HeaderProps> = ({
  signalState,
  vehicleId,
  vehicleLicensePlate,
  icon,
  ...props
}) => {
  return (
    <div className="mn-vehicle-details__header" {...props}>
      <div className="mn-vehicle-details__header-left">
        <div className="mn-vehicle-details__signal-id">
          <Signal mode={signalState} />
          <span className="mn-vehicle-details__id">{vehicleId}</span>
        </div>
        <span className="mn-vehicle-details__license-plate">
          {vehicleLicensePlate}
        </span>
      </div>
      <div className="mn-vehicle-details__header-right">{icon}</div>
    </div>
  );
};

interface LiveDataProps {
  isTracking: any;
  liveData: {
    currentSpeed?: {} | number | null;
    currentAutonomy?:
      | string
      | number
      | boolean
      | {}
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactNodeArray
      | React.ReactPortal
      | null;
    speedBar?: string | number | readonly string[];
    autonomyBar?: string | number | readonly string[];
  };
}

const LiveData: React.FC<LiveDataProps> = ({ isTracking, liveData }) => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  let currentSpeed = preferencesContext.isMetric ? (
    <p>
      {liveData.currentSpeed}
      <span className="live-data-unit">km/h</span>
    </p>
  ) : (
    <p>
      {kmPerHToMilesPerH(liveData.currentSpeed).toFixed(0)}
      <span className="live-data-unit">mph</span>
    </p>
  );
  let currentAutonomy = (
    <p>
      {formatData(
        Number(liveData.currentAutonomy),
        kmToMiles,
        preferencesContext,
        0
      )}
      <span className="live-data-unit">
        {preferencesContext.isMetric ? "km" : "mil"}
      </span>
    </p>
  );
  return (
    <>
      {isTracking &&
      liveData.currentAutonomy &&
      (liveData.currentSpeed || liveData.currentSpeed === 0) ? (
        <div className="mn-vehicle-details__live-data">
          <div className="mn-vehicle-details__live-data-left">
            <div className="live-data__icon">
              <IconTachometer size={14} color="--global-colors-ink-dark" />
            </div>
            <div className="live-data__data">
              {currentSpeed}
              <progress
                value={liveData.speedBar}
                max="100"
                className="progress-bar progress-bar--green"
              />
            </div>
          </div>
          <div className="mn-vehicle-details__live-data-right">
            <div className="live-data__icon">
              <IconGasStation size={14} color="--global-colors-ink-dark" />
            </div>
            <div className="live-data__data">
              {currentAutonomy}
              <progress
                value={liveData.autonomyBar}
                max="100"
                className="progress-bar progress-bar--green"
              />
            </div>
          </div>
        </div>
      ) : (
        isTracking &&
        (liveData.currentSpeed || liveData.currentSpeed === 0) && (
          <div className="mn-vehicle-details__live-data-onlyspeed_container">
            <div className="mn-vehicle-details__live-data-onlyspeed">
              <div className="live-data__icon">
                <IconTachometer size={14} color="--global-colors-ink-dark" />
              </div>
              <div className="live-data__data">
                {currentSpeed}
                <progress
                  value={liveData.speedBar}
                  max="100"
                  className="progress-bar progress-bar--green"
                />
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

interface StatusProps {
  currentPosition: string;
  positions: any[];
  pointsToShow?: number;
  disabled: boolean;
  liveData: {
    currentSpeed?: {} | number | null;
    currentAutonomy?:
      | string
      | number
      | boolean
      | {}
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactNodeArray
      | React.ReactPortal
      | null;
    speedBar?: string | number | readonly string[];
    autonomyBar?: string | number | readonly string[];
  };
  onTracking: (arg0: any) => any;
  status?: StatusVehicleType;
  isTracking?: boolean;
  lastUpdate?: Date;
  gtfs?: boolean;
  nextStopIndex?: number;
  startPublicStop?: PublicStopStatus;
  nextPublicStop?: PublicStopETA;
  publicTransportStatus?: PublicTransportStatus;
  nextPublicStops?: PublicStopETA[];
  prevStops?: PublicStopStatus[];
}

const Status: React.FC<StatusProps> = ({
  currentPosition,
  positions,
  pointsToShow = 5,
  disabled,
  liveData,
  onTracking,
  status,
  isTracking,
  lastUpdate,
  gtfs,
  nextStopIndex,
  startPublicStop,
  nextPublicStop,
  publicTransportStatus,
  nextPublicStops,
  prevStops,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const startPosition = positions[0];
  const length = positions.length;
  const lastPosition = positions[length - 1];
  const isShowStartPointOnly = length === 1;
  const isHideMidlePoints = length === 2;
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  let publicTransportVehicleId = publicTransportStatus?.id;

  let points = pointsToShow;
  if (pointsToShow >= length) {
    if (pointsToShow > 2) {
      points = length - 2;
    }
  } else if (length - pointsToShow === 1) {
    points = pointsToShow - 1;
  }
  const expandPositionsList = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    setIsExpanded(false);
  }, [positions]);

  useEffect(() => {
    let currStopIndex =
      publicTransportStatus?.currPublicStop?.publicStopIndex ?? 0;
    let element = document.getElementById(
      `public-trasnsport-stop-${currStopIndex}`
    );
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [publicTransportVehicleId]);

  function convertInHourAndMinute(estimatedTime: String) {
    if (estimatedTime != null) {
      const [hours, minutes] = estimatedTime.split(":");
      const formattedTime = `${hours}:${minutes}`;
      return formattedTime;
    }
    return "00:00";
  }

  function getTimeColor(position: any) {
    if (position != null) {
      let realTimeSeconds = moment.duration(position.realTime).asSeconds();

      let staticTimeSeconds = moment
        .duration(`${position.staticTime}:00`)
        .asSeconds();

      let difference = realTimeSeconds - staticTimeSeconds;

      if (difference > 60) {
        return "stop-late";
      } else {
        return "stop-ontime";
      }
    }
  }

  function getStopMessage(position: any) {
    if (position != null) {
      let realTimeSeconds = moment.duration(position.realTime).asSeconds();

      let staticTimeSeconds = moment
        .duration(`${position.staticTime}:00`)
        .asSeconds();

      let difference = realTimeSeconds - staticTimeSeconds;

      if (difference > 60) {
        return t("fleetControl.gtfs.late");
      } else if (difference < -60) {
        return t("fleetControl.gtfs.advance");
      } else {
        return t("fleetControl.gtfs.ontime");
      }
    }
  }

  function getColorShere(position: PublicStopETA) {
    let classColorShere = "";
    if (
      position != null &&
      position.publicStopETAStatus != null &&
      nextStopIndex != null
    ) {
      if (position.stopSequence < nextStopIndex) {
        return "prev-stop";
      } else if (position.stopSequence === nextStopIndex) {
        return "curr-stop";
      }
    }
    return classColorShere;
  }

  return (
    <div className="mn-vehicle-details__status" {...props}>
      <LiveData isTracking={isTracking && !disabled} liveData={liveData} />
      <div>{t("fleetControl.vehicleDetailsStatus.status")}</div>
      <div className="mn-vehicle-details__location-history">
        {
          <div>
            <div className="mn-vehicle-details__location-history-end">
              <DataLabel
                dataText={
                  status == "OFFLINE" || status == "PARKING"
                    ? currentPosition ?? t("common.na")
                    : lastPosition.address ?? t("common.na")
                }
                icon={<IconLocator size={14} />}
                label={t("fleetControl.vehicleDetailsStatus.lastPosition")}
              />
              <time>
                {status == "OFFLINE" || status == "PARKING"
                  ? lastUpdate
                    ? ConvertTimeZone(
                        lastUpdate,
                        preferencesContext.timeZone,
                        preferencesContext.localeFormat,
                        true
                      )
                    : "N/A"
                  : lastPosition
                  ? ConvertTimeZone(
                      lastPosition.gpsPositionTimestamp,
                      preferencesContext.timeZone,
                      preferencesContext.localeFormat,
                      true
                    )
                  : "N/A"}
              </time>
            </div>
            {gtfs &&
              (status === "MOVING" || status === "STOP") &&
              nextPublicStop && (
                <div className="mn-public-transport__next-stop mn-vehicle-details_current_padding">
                  <DataLabel
                    dataText={`${
                      nextPublicStop.stopSequence ?? t("common.na")
                    } - ${nextPublicStop?.publicStopName ?? t("common.na")}`}
                    icon={<IconUp size={14} />}
                    label={t("fleetControl.gtfs.nextstop")}
                  />
                  <Tooltip />
                  <span
                    data-for="tooltip"
                    data-tooltip-delay-hide={1000}
                    data-tip={t("fleetControl.gtfs.expected")}
                  >
                    <time className="next-stop">
                      {nextPublicStop?.estimatedTime &&
                        convertInHourAndMinute(nextPublicStop?.estimatedTime)}
                    </time>
                  </span>
                </div>
              )}
            {!isHideMidlePoints && (status === "MOVING" || status === "STOP") && (
              <div
                className={
                  gtfs && nextPublicStops != null && nextPublicStops.length > 0
                    ? "public-stop-list"
                    : ""
                }
              >
                <ul
                  className="mn-vehicle-details__location-history-path"
                  onClick={expandPositionsList}
                >
                  {!gtfs ? (
                    isExpanded ? (
                      positions
                        .slice(length - points - 1, length - 1)
                        .reverse()
                        .map((position: GPSPositions, index: number) => {
                          return (
                            <li key={index} className="points-list">
                              <span>{position.address}</span>
                            </li>
                          );
                        })
                    ) : (
                      <li>
                        <span>
                          {(function () {
                            if (length <= 2 && !isExpanded) {
                              return t("common.na");
                            } else {
                              return `${length - 2}  ${t(
                                "fleetControl.vehicleDetailsStatus.points"
                              )}`;
                            }
                          })()}
                        </span>
                      </li>
                    )
                  ) : (
                    nextPublicStops != null &&
                    nextPublicStops.length > 0 &&
                    nextPublicStops
                      .filter((stop): stop is PublicStopETA => stop != null)
                      .map((position: PublicStopETA) => {
                        return (
                          <li
                            key={`public-trasnsport-stop-${position.stopSequence}`}
                            id={`public-trasnsport-stop-${position.stopSequence}`}
                            className={getColorShere(position)}
                          >
                            <span className="name-stop">
                              {`${position.stopSequence ?? t("common.na")} - ${
                                position.publicStopName
                              }`}
                            </span>
                            <span>
                              <Tooltip />
                              <span
                                data-for="tooltip"
                                data-tooltip-delay-hide={1000}
                                data-tip=""
                                className="time-stop"
                              >
                                <time>
                                  {position.estimatedTime != null &&
                                    convertInHourAndMinute(
                                      position.estimatedTime
                                    )}
                                </time>
                              </span>
                            </span>
                          </li>
                        );
                      })
                  )}
                  {gtfs &&
                    publicTransportStatus &&
                    publicTransportStatus.currPublicStop != null && (
                      <li
                        key={`public-trasnsport-stop-${publicTransportStatus.currPublicStop.publicStopIndex}`}
                        id={`public-trasnsport-stop-${publicTransportStatus.currPublicStop.publicStopIndex}`}
                        className="prev-stop"
                      >
                        <span className="name-stop">
                          {`${
                            publicTransportStatus.currPublicStop
                              .publicStopIndex ?? t("common.na")
                          } - ${publicTransportStatus.currPublicStop.name}`}
                        </span>
                        <span>
                          <Tooltip />
                          <span
                            data-for="tooltip"
                            data-tooltip-delay-hide={1000}
                            data-tip={getStopMessage(
                              publicTransportStatus.currPublicStop
                            )}
                            className="time-stop"
                          >
                            <time
                              className={getTimeColor(
                                publicTransportStatus.currPublicStop
                              )}
                            >
                              {publicTransportStatus.currPublicStop.realTime !=
                                null &&
                                convertInHourAndMinute(
                                  publicTransportStatus.currPublicStop.realTime
                                )}
                            </time>
                          </span>
                        </span>
                      </li>
                    )}
                  {gtfs &&
                    prevStops != null &&
                    prevStops.length > 0 &&
                    prevStops.map((position: PublicStopStatus) => {
                      return (
                        <li
                          key={`public-trasnsport-stop-${position.publicStopIndex}`}
                          id={`public-trasnsport-stop-${position.publicStopIndex}`}
                          className="prev-stop"
                        >
                          <span className="name-stop">
                            {`${position.publicStopIndex ?? t("common.na")} - ${
                              position.name
                            }`}
                          </span>
                          <span>
                            <Tooltip />
                            <span
                              data-for="tooltip"
                              data-tooltip-delay-hide={1000}
                              data-tip={getStopMessage(position)}
                              className="time-stop"
                            >
                              <time className={getTimeColor(position)}>
                                {position.realTime != null &&
                                  convertInHourAndMinute(position.realTime)}
                              </time>
                            </span>
                          </span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        }
        {!gtfs
          ? (status === "MOVING" || status === "STOP") && (
              <div
                className={
                  isHideMidlePoints
                    ? "mn-vehicle-details__location-history-start mn-vehicle-details__without-midlePoints"
                    : "mn-vehicle-details__location-history-start"
                }
              >
                <DataLabel
                  dataText={startPosition.address ?? t("common.na")}
                  icon={<IconFlag size={14} />}
                  label={t("fleetControl.vehicleDetailsStatus.start")}
                />
                <time>
                  {startPosition
                    ? ConvertTimeZone(
                        startPosition.gpsPositionTimestamp,
                        preferencesContext.timeZone,
                        preferencesContext.localeFormat,
                        true
                      )
                    : "N/A"}
                </time>
              </div>
            )
          : (status === "MOVING" || status === "STOP") &&
            startPublicStop != null && (
              <div
                className={
                  isHideMidlePoints
                    ? "mn-vehicle-details__location-history-start mn-vehicle-details__without-midlePoints"
                    : "mn-vehicle-details__location-history-start"
                }
              >
                <DataLabel
                  dataText={startPublicStop.name ?? t("common.na")}
                  icon={<IconFlag size={14} />}
                  label={t("fleetControl.vehicleDetailsStatus.start")}
                />
                <time>
                  {startPublicStop.realTime != null &&
                    convertInHourAndMinute(startPublicStop.realTime)}
                </time>
              </div>
            )}
        {!disabled && !isTracking ? (
          <Button
            aspect="primary"
            label={t("fleetControl.vehicleDetailsStatus.liveTrack")}
            onClick={() => onTracking(isTracking)}
            size="small"
            disabled={status !== "MOVING" && status !== "STOP"}
          >
            <IconLocator color="--global-colors-ui-white" size={14} />
          </Button>
        ) : (
          !disabled && (
            <Button
              aspect="danger"
              label={t("fleetControl.vehicleDetailsStatus.stopTrack")}
              onClick={() => onTracking(isTracking)}
              size="small"
            />
          )
        )}
      </div>
    </div>
  );
};

interface DriverProps {
  driver: Driver;
  driverStatus: DriverStatus;
  vehicleType: string;
  vehicle: Vehicle;
  gtfs?: boolean;
  routeName?: string;
  tripIndex?: number;
}

const DriverDetails: React.FC<DriverProps> = ({
  driver,
  driverStatus,
  vehicle,
  vehicleType = "CAR",
}) => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  let driverName = !_.isEmpty(driver)
    ? driver.firstName + " " + driver.lastName
    : `${t("common.na")}`;
  let drivingSince = driverStatus?.dynamicFields?.drivingSince
    ? dayjs.duration(driverStatus?.dynamicFields?.drivingSince).format("HH:mm")
    : "-- : --";
  let breakTime = driverStatus?.dynamicFields?.breakTime
    ? dayjs.duration(driverStatus?.dynamicFields?.breakTime).format("HH:mm")
    : "-- : --";
  let available = driverStatus?.dynamicFields?.available
    ? dayjs.duration(driverStatus?.dynamicFields?.available).format("HH:mm")
    : "-- : --";
  let todayHours = driverStatus?.dynamicFields?.todayHours
    ? dayjs.duration(driverStatus?.dynamicFields?.todayHours).format("HH:mm")
    : "-- : --";
  let tomorrowHours = driverStatus?.dynamicFields?.tomorrowHours
    ? dayjs.duration(driverStatus?.dynamicFields?.tomorrowHours).format("HH:mm")
    : "-- : --";
  let driverLastUpdate = driverStatus?.dynamicFields?.lastUpdate
    ? ConvertTimeZone(
        driverStatus?.dynamicFields?.lastUpdate,
        preferencesContext.timeZone,
        preferencesContext.localeFormat
      )
    : "";
  let totalToday = driverStatus?.dynamicFields?.totalToday
    ? dayjs.duration(driverStatus?.dynamicFields?.totalToday).format("HH:mm")
    : "-- : --";
  let isMetric = preferencesContext.isMetric
    ? t("fleetControl.vehicleDetailsDriver.kmTraveled")
    : t("common.milesTraveled");
  let kmTraveled = "0";
  if (driverStatus?.dynamicFields?.kmTraveled) {
    kmTraveled = preferencesContext.isMetric
      ? numberAnnotation(
          driverStatus?.dynamicFields?.kmTraveled / 1000,
          1
        ).toString()
      : numberAnnotation(
          kmToMiles(driverStatus?.dynamicFields?.kmTraveled / 1000),
          1
        ).toString();
  }
  return (
    <div className="mn-vehicle-details__driver">
      <div>{t("fleetControl.vehicleDetailsDriver.driver")}</div>
      <div className="mn-vehicle-details__driver-detail">
        {vehicleType === "Truck" ? (
          <>
            <div className="mn-vehicle-details__driver-detail-upper-row">
              <DataLabel
                dataText={driverName}
                icon={
                  <ThumbProfile
                    size="small"
                    imgUrl={driver.avatar}
                    alt="User Thumbnail"
                  />
                }
                label={t("fleetControl.vehicleDetailsDriver.currentDriver")}
              />
              <DataLabel
                dataText={driverStatus?.dynamicFields?.slot ?? t("common.na")}
                icon={<IconLicense size={14} />}
                label={t("fleetControl.vehicleDetailsDriver.chronotachograph")}
              />
            </div>
            <div className="mn-vehicle-details__driver-detail-lower-row">
              <div className="mn-chart mn-chart--simple-line">
                <span className="mn-chart__label">
                  {t("fleetControl.vehicleDetailsDriver.drivingSince")}
                </span>
                <span className="mn-chart__data">{drivingSince}</span>
                <progress
                  value={driverStatus?.dynamicFields?.drivingBar ?? 0}
                  max="100"
                  className="progress-bar progress-bar--green"
                />
              </div>
              <div className="mn-chart mn-chart--simple-line">
                <span className="mn-chart__label">
                  {t("fleetControl.vehicleDetailsDriver.nextBreak")}
                </span>
                <span className="mn-chart__data">{breakTime}</span>
                <progress
                  value={driverStatus?.dynamicFields?.breakBar ?? 0}
                  max="100"
                  className="progress-bar progress-bar--orange"
                />
              </div>
              <div className="mn-chart mn-chart--simple-line">
                <span className="mn-chart__label">
                  {t("fleetControl.vehicleDetailsDriver.available")}
                </span>
                <span className="mn-chart__data">{available}</span>
                <progress
                  value={driverStatus?.dynamicFields?.availableBar ?? 0}
                  max="100"
                  className="progress-bar progress-bar--blue"
                />
              </div>
            </div>
            <div className="mn-vehicle-details__bottom">
              <div className="mn-vehicle-details__bottom-upper-row">
                <DataLabel
                  dataText={todayHours}
                  label={t("fleetControl.vehicleDetailsDriver.todayCycle")}
                />
                <DataLabel
                  dataText={tomorrowHours}
                  label={t(
                    "fleetControl.vehicleDetailsDriver.hoursAvailableTomorrow"
                  )}
                />
              </div>
              <div className="mn-vehicle-details__bottom-lower-row">
                {t("fleetControl.vehicleDetailsDriver.lastUpdate")}
                {driverLastUpdate}
              </div>
              <div className="mn-vehicle-details__actions">
                <Button
                  label={t("fleetControl.vehicleDetailsDriver.details")}
                  size="small"
                  disabled={!(driver && !_.isEmpty(driver))}
                  onClick={() => {
                    alert("Details clicked");
                  }}
                >
                  <IconList color="--global-colors-ink-ink" size={14} />
                </Button>
                {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
                  process.env.REACT_APP_DEPLOY_TARGET === "stage") && (
                  <Button
                    label={t("fleetControl.vehicleDetailsDriver.message")}
                    size="small"
                    disabled={!(driver && !_.isEmpty(driver))}
                    onClick={() => {
                      alert("Message clicked");
                    }}
                  >
                    <IconMessages color="--global-colors-ink-ink" size={14} />
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mn-vehicle-details__driver-detail-upper-row">
              <DataLabel
                dataText={driverName}
                icon={
                  <ThumbProfile
                    size="small"
                    imgUrl={driver.avatar}
                    alt="User Thumbnail"
                  />
                }
                label={t("fleetControl.vehicleDetailsDriver.currentDriver")}
              />
            </div>
            <div className="mn-vehicle-details__driver-detail-second-row-identification">
              {vehicle?.driverIdentifiedBy ? (
                <DataLabel
                  dataText={vehicle?.driverIdentifiedBy ?? t("common.na")}
                  icon={<IconDevices size={14} />}
                  label={t("fleetControl.vehicleDetailsDriver.detectedBy")}
                />
              ) : null}
            </div>
            <div className="mn-vehicle-details__driver-detail-lower-row-second-type">
              <div className="mn-vehicle-details-block">
                <div className="mn-vehicle-details__driver-green-row" />
                <div className="mn-chart mn-chart--simple-line">
                  <span className="mn-chart__label">
                    {t("fleetControl.vehicleDetailsDriver.drivingSince")}
                  </span>
                  <span className="mn-chart__data">{drivingSince}</span>
                </div>
              </div>
              <div className="mn-vehicle-details-block">
                <div className="mn-vehicle-details__driver-orange-row" />
                <div className="mn-chart mn-chart--simple-line">
                  <span className="mn-chart__label">
                    {t("fleetControl.vehicleDetailsDriver.totalToday")}
                  </span>
                  <span className="mn-chart__data">{totalToday}</span>
                </div>
              </div>
              <div className="mn-vehicle-details-block">
                <div className="mn-vehicle-details__driver-sky-blue-row" />
                <div className="mn-chart mn-chart--simple-line">
                  <span className="mn-chart__label">{isMetric}</span>
                  <span className="mn-chart__data">{kmTraveled}</span>
                </div>
              </div>
            </div>
            <div className="mn-vehicle-details__bottom">
              <div className="mn-vehicle-details__bottom-lower-row-second-type">
                {t("fleetControl.vehicleDetailsDriver.lastUpdate")}:{" "}
                {driverLastUpdate}
              </div>
              <div className="mn-vehicle-details__actions">
                <Link
                  to={"/dashboard/drivers/details/" + driver.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button
                    label={t("fleetControl.vehicleDetailsDriver.details")}
                    size="small"
                    id="driver-details"
                    disabled={!(driver && !_.isEmpty(driver))}
                  >
                    <IconList color="--global-colors-ink-ink" size={14} />
                  </Button>
                </Link>
                {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
                  process.env.REACT_APP_DEPLOY_TARGET === "stage") && (
                  <Button
                    label={t("fleetControl.vehicleDetailsDriver.message")}
                    size="small"
                    disabled={!(driver && !_.isEmpty(driver))}
                    onClick={() => {
                      alert(t("common.underdevelopment"));
                    }}
                  >
                    <IconMessages color="--global-colors-ink-ink" size={14} />
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

interface PubliTransportDetailsProps {
  routeName?: string;
  tripIndex?: number;
}

const PubliTransportDetails: React.FC<PubliTransportDetailsProps> = ({
  routeName,
  tripIndex,
}) => {
  return routeName && tripIndex ? (
    <div className="mn-public-transport__info">
      <div>{t("fleetControl.gtfs.info")}</div>
      <div className="mn-public-transport__stats">
        {routeName && (
          <DataLabel dataText={routeName} icon={<IconMenuRoutes size={14} />} />
        )}
        {tripIndex && (
          <DataLabel
            dataText={t("fleetControl.gtfs.ride") + " " + tripIndex}
            icon={<IconExchange size={14} />}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

interface VehicleProps {
  liveData: {
    currentSpeed?: number;
    currentAutonomy?:
      | string
      | number
      | boolean
      | {}
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactNodeArray
      | React.ReactPortal
      | null;
    speedBar?: string | number | readonly string[];
    autonomyBar?: string | number | readonly string[];
  };
  vehicle: Vehicle;
  isTracking: boolean;
  vehicleStatus: VehicleStatus;
  fleetView: FleetView;
}

const VehicleDetails: React.FC<VehicleProps> = ({
  liveData,
  vehicle,
  vehicleStatus,
  fleetView,
  isTracking,
}) => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  let fuelLevelLiters = null;
  if (vehicleStatus?.dynamicFields?.fuelLevelLiters) {
    if (preferencesContext.isMetric) {
      fuelLevelLiters =
        numberAnnotation(vehicleStatus?.dynamicFields?.fuelLevelLiters) + " lt";
    } else {
      fuelLevelLiters =
        numberAnnotation(
          ltToGal(vehicleStatus?.dynamicFields?.fuelLevelLiters)
        ) + "gal";
    }
  }
  let fuelLevel = null;
  if (vehicleStatus?.dynamicFields?.fuelLevel) {
    fuelLevel = numberAnnotation(vehicleStatus?.dynamicFields?.fuelLevel) + "%";
  }
  let odometer = null;
  if (vehicleStatus.dynamicFields?.odometer) {
    if (preferencesContext.isMetric) {
      odometer =
        numberAnnotation(mtToKm(vehicleStatus.dynamicFields?.odometer), 0) +
        " km";
    } else {
      odometer =
        numberAnnotation(
          kmToMiles(mtToKm(vehicleStatus.dynamicFields?.odometer)),
          0
        ) + " mi";
    }
  }
  let sectionName = t("fleetControl.vehicleDetailsVehicle.vehicle");
  if (vehicle?.type === "HUMAN_BEING") {
    sectionName = t("fleetControl.vehicleDetailsVehicle.humanBeing");
  }

  const checkGpsSignal = () => {
    let fix = vehicleStatus?.dynamicFields?.fix ?? false;
    let hdop = vehicleStatus?.dynamicFields?.hdop ?? 20;
    let pdop = vehicleStatus?.dynamicFields?.pdop ?? hdop;
    if (!fix || (hdop >= 20 && pdop >= 20)) {
      return "absent";
    } else if (fix && hdop > 10 && pdop > 10) {
      return "poor";
    } else if (fix && hdop <= 2 && pdop <= 2) {
      return "optimal";
    } else if (fix && hdop <= 10 && pdop <= 10) {
      return "fair";
    }
  };

  const checkGprsSignal = () => {
    let fix = vehicleStatus?.dynamicFields?.fix ?? false;
    let gsm = vehicleStatus?.dynamicFields?.gsm ?? 0;
    if (!fix || gsm == 0) {
      return "absent";
    } else if (fix && gsm == 1) {
      return "poor";
    } else if (fix && gsm == 2) {
      return "fair";
    } else if (fix && gsm >= 3) {
      return "optimal";
    }
  };

  return (
    <div className="mn-vehicle-details__vehicle">
      <div>{sectionName}</div>
      <div className="mn-vehicle-details__stats">
        {vehicle.model && (
          <DataLabel
            dataText={`${
              vehicle.brand.charAt(0).toUpperCase() +
              vehicle.brand.slice(1).toLowerCase()
            } ${
              vehicle.model.charAt(0).toUpperCase() +
              vehicle.model.slice(1).toLowerCase()
            }`}
            icon={getIconFromVehicleType(vehicle?.type, 14, undefined)}
          />
        )}
        {!!liveData.currentSpeed &&
          !isTracking &&
          vehicleStatus.dynamicFields?.vehicleStatus === "MOVING" && (
            <DataLabel
              dataText={`${numberAnnotation(liveData.currentSpeed)} ${
                preferencesContext.isMetric ? "km/h" : "mph"
              }`}
              icon={<IconTachometer size={14} />}
              label={t("fleetControl.vehicleDetailsVehicle.speed")}
            />
          )}
        {vehicle.fleet && (
          <DataLabel
            dataText={fleetView.name}
            icon={<IconLayers size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.group")}
          />
        )}
        {vehicleStatus.dynamicFields?.externalBatteryLevel ? (
          <DataLabel
            dataText={
              numberAnnotation(
                vehicleStatus.dynamicFields?.externalBatteryLevel,
                1
              ) + " V"
            }
            icon={<ExternalBattery size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.externalBattery")}
          />
        ) : null}
        {vehicleStatus.dynamicFields?.dataSource ? (
          <DataLabel
            dataText={vehicleStatus.dynamicFields?.dataSource}
            icon={<IconAutomations size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.dataSource")}
          />
        ) : null}
        {vehicleStatus.dynamicFields?.internalBatteryLevel ? (
          <DataLabel
            className="internal-battery-icon"
            dataText={
              numberAnnotation(
                vehicleStatus.dynamicFields?.internalBatteryLevel,
                1
              ) + " V"
            }
            icon={<IconBattery size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.internalBattery")}
          />
        ) : null}
        {vehicleStatus.dynamicFields?.ignitionKey && (
          <DataLabel
            dataText={vehicleStatus.dynamicFields?.ignitionKey && "On"}
            icon={<IconKey size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.ignition")}
          />
        )}
        {fuelLevelLiters && (
          <DataLabel
            dataText={fuelLevelLiters}
            icon={<IconGasStation size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.fuel")}
          />
        )}
        {odometer && (
          <DataLabel
            dataText={odometer}
            icon={<IconOdometer size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.odometer")}
          />
        )}
        {vehicleStatus.dynamicFields?.utilizationMinutes &&
        _.isEqual(vehicle?.type, typeOfVehicleValues.OPERATING_MACHINE) ? (
          <DataLabel
            dataText={formatMinutes(
              vehicleStatus.dynamicFields?.utilizationMinutes
            )}
            icon={<IconUtilization size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.utilizationMinutes")}
          />
        ) : null}
        {vehicleStatus.dynamicFields?.door && (
          <DataLabel
            dataText={vehicleStatus.dynamicFields?.door}
            icon={<IconWarning size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.door")}
          />
        )}
        {vehicleStatus.dynamicFields?.trailerTruck && (
          <DataLabel
            dataText={vehicleStatus.dynamicFields?.trailerTruck}
            icon={<IconTrailerTruck size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.trailerTruck")}
          />
        )}
        {vehicleStatus.dynamicFields?.temperature && (
          <DataLabel
            dataText={vehicleStatus.dynamicFields?.temperature}
            icon={<IconTemperature size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.temp")}
          />
        )}
        {vehicleStatus.dynamicFields?.siren && (
          <DataLabel
            dataText={vehicleStatus.dynamicFields?.siren}
            icon={<IconAlarmSiren size={14} />}
            label={t("fleetControl.vehicleDetailsVehicle.siren")}
          />
        )}
        {vehicleStatus.dynamicFields?.gsm && isTracking && (
          <DataLabel
            hasIssue={
              !vehicleStatus.dynamicFields.fix || checkGprsSignal() === "absent"
            }
            dataText={
              "" +
              t(`fleetControl.vehicleDetailsVehicle.${checkGprsSignal()}Signal`)
            }
            icon={<SignalIndicator size={14} isBar value={checkGprsSignal()} />}
            label={t("fleetControl.vehicleDetailsVehicle.gprsSignal")}
          />
        )}
        {vehicleStatus.dynamicFields?.hdop &&
          isTracking &&
          !!vehicleStatus.dynamicFields?.satellites && (
            <DataLabel
              hasIssue={
                !vehicleStatus.dynamicFields.fix ||
                checkGpsSignal() === "absent"
              }
              dataText={
                t(
                  `fleetControl.vehicleDetailsVehicle.${checkGpsSignal()}Signal`
                ) +
                ", " +
                vehicleStatus.dynamicFields.satellites +
                " Sat"
              }
              icon={<SignalIndicator size={14} value={checkGpsSignal()} />}
              label={t(`fleetControl.vehicleDetailsVehicle.gpsSignal`)}
            />
          )}
      </div>

      {vehicleStatus.dynamicFields?.lastUpdate ? (
        <div className="mn-vehicle-details__bottom-lower-row-second-type">
          {t("fleetControl.vehicleDetailsDriver.lastUpdate")}:{" "}
          {ConvertTimeZone(
            vehicleStatus?.dynamicFields?.lastUpdate,
            preferencesContext.timeZone,
            preferencesContext.localeFormat
          )}
        </div>
      ) : (
        <div className="mn-vehicle-details__bottom-lower-row-second-type">
          {t("fleetControl.vehicleDetailsDriver.lastUpdate")}:{" "}
        </div>
      )}
      <div className="mn-vehicle-details__actions">
        <Link
          to={
            "/dashboard/fleets/" +
            vehicle.fleet +
            "/vehicles/" +
            vehicle.id +
            "/details"
          }
          target="_blank"
          rel="noreferrer"
        >
          <Button
            label={t("fleetControl.vehicleDetailsVehicle.details")}
            size="small"
          >
            <IconList color="--global-colors-ink-ink" size={14} />
          </Button>
        </Link>

        <div
          className={`mn-vehicle-details__lh-button${
            t("fleetControl.vehicleDetailsVehicle.locationHistory").length > 15
              ? "-long"
              : ""
          }`}
        >
          <Link
            to={
              "/dashboard/vehicles/location-history/tracks" +
              getQueryString({
                firstPointDate: [
                  dayjs(new Date().setHours(0, 0, 0, 0)).format(
                    "YYYY/MM/DD HH:mm"
                  ),
                  dayjs().format("YYYY/MM/DD HH:mm"),
                ],
                "vehicle.id": vehicle.id,
              })
            }
            target="_blank"
            rel="noreferrer"
          >
            <Button
              label={t("fleetControl.vehicleDetailsVehicle.locationHistory")}
              size="small"
            >
              <IconLocationHistory color="--global-colors-ink-ink" size={14} />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

interface AssetProps {
  assets: Asset[];
}

const AssetDetails: React.FC<AssetProps> = ({ assets }) => {
  return (
    <div className="mn-vehicle-details__vehicle">
      <div>{t("fleetControl.vehicleDetailsAsset.assets")}</div>
      <div className="mn-vehicle-details__assets">
        {assets.map((asset: Asset) => (
          <DataLabel
            key={asset.id}
            dataText={`${asset.name}`}
            icon={<IconDevices size={14} />}
          />
        ))}
      </div>
    </div>
  );
};

VehicleDetailsModal.Header = Header;
VehicleDetailsModal.Status = Status;
VehicleDetailsModal.LiveData = LiveData;
VehicleDetailsModal.DriverDetails = DriverDetails;
VehicleDetailsModal.PubliTransportDetails = PubliTransportDetails;
VehicleDetailsModal.VehicleDetails = VehicleDetails;
VehicleDetailsModal.AssetDetails = AssetDetails;
export default VehicleDetailsModal;
