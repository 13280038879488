import dayjs from "dayjs";
import { t } from "i18next";
import _, { isString } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { User } from "../../../stories/Icon.line.stories";
import { TextFieldDropdown } from "../../../ui/Chat/TextFieldDropdown";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import { Checkbox } from "../../../ui/Forms/Checkbox";
import Form from "../../../ui/Forms/Form";
import { Radio } from "../../../ui/Forms/Radio";
import { SingleDatePicker } from "../../../ui/Forms/SingleDatePicker";
import TextField from "../../../ui/Forms/TextField";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconAttach } from "../../../ui/Icon/Line/Attach";
import { IconBuild } from "../../../ui/Icon/Line/Build";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconEdit } from "../../../ui/Icon/Line/Edit";
import { IconMinus } from "../../../ui/Icon/Line/Minus";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  convertDaysOfWeek,
  convertMonths,
  getDayOfWeek,
  getMonthStringified,
  getQueryString,
  getReportRoute,
} from "../../../utils/Utils";
import {
  AddressBook,
  addressBookSelectors,
} from "../../address/addressBookSlice";
import AddressBookModals from "../../notification/rule/modals/AddressBookModal";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import {
  createNewSchedulationAsync,
  CronExpressionRequest,
  getReportScheduledAsync,
  ReportArchive,
  ReportPeriodEnum,
  reportsArchiveEmptyState,
  reportsArchiveSelectors,
  ReportSchedulateRequest,
  updateReportSchedulationAsync,
  WeekSequenceEnumValues,
} from "../archive/reportArchiveSlice";
import { ReportType } from "../reportsMetadataSlice";
import "./SchedulationModal.css";

interface SchedulationModalProps {
  isOpen: boolean;
  onClose: () => any;
  reportType: ReportType;
  customEntities: DropDownItem[];
  customEntitiesParamName?: string;
  customTextLabel: String;
  customIcon: React.ReactFragment;
  customEntitiesTwo?: DropDownItem[];
  customEntitiesTwoParamName?: string;
  customTextLabelTwo?: String;
  customIconTwo?: React.ReactFragment;
  paramsComponents?: React.ReactFragment[];
  params?: any;
  paramsToSchedulate?: any;
  schedulationId?: string;
}

interface DropDownItem {
  id?: number | string;
  title: string;
  iconTitle?: JSX.Element;
  subTitle?: string;
  type?: string;
  phone?: string;
  icon?: JSX.Element;
  iconSubTitle?: JSX.Element;
  clickCallback?: (id: number) => void;
}

interface QueryParams {
  [paramName: string]: any;
}

enum DayOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

enum Month {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

const WeekSequenceEnumToNumber = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
  FOURTH: 3,
  LAST: 4,
};

export const SchedulationModal: React.FC<SchedulationModalProps> = ({
  isOpen,
  onClose,
  reportType,
  customEntities,
  customTextLabel,
  customIcon,
  customEntitiesTwo,
  customTextLabelTwo,
  customEntitiesParamName,
  customEntitiesTwoParamName,
  customIconTwo,
  paramsComponents,
  params,
  paramsToSchedulate,
  schedulationId,
}) => {
  const navigate = useNavigate();
  const [errorList, setErrorList] = useState([]);
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [resetKey, setResetKey] = useState(0);
  const [elementOpened, setElementOpened] =
    useState<
      | ""
      | "NAME"
      | "TIME"
      | "TYPE"
      | "USERS"
      | "CHILDREN"
      | "SECONDCHILDREN"
      | "PARAMSCOMPONENTS"
    >("");

  const weekSequenceArray = [
    WeekSequenceEnumValues.FIRST,
    WeekSequenceEnumValues.SECOND,
    WeekSequenceEnumValues.THIRD,
    WeekSequenceEnumValues.FOURTH,
    WeekSequenceEnumValues.LAST,
  ];

  const [addressBookModal, setAddressBookModal] = useState(false);

  //CHILDREN SECTION
  const [customEntitiesSelected, setCustomEntitiesSelected] = useState<
    DropDownItem[]
  >([]);
  const [customEntitiesTwoSelected, setCustomEntitiesTwoSelected] = useState<
    DropDownItem[]
  >([]);
  //NAME SECTION
  const [name, setName] = useState("");
  //TIME SECTION
  const [timePeriod, setTimePeriod] = useState<ReportPeriodEnum>("DAILY");
  const [timeFrequency, setTimeFrequency] = useState<"DAYS" | "EVERY">("DAYS");
  const [recursion, setRecursion] = useState<string>("");
  const [day, setDay] = useState<number>(1);
  const [monthsSelected, setMonthsSelected] = useState<any>();
  const [dayMonths, setDayMonths] = useState<any>();
  const [everyTimeMonth, setEveryTimeMonth] = useState<any>();
  const [everyTimeDay, setEveryTimeDay] = useState<any>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  //EXPORT TYPE SECTION
  const [pdfExport, setPdfExport] = useState(false);
  const [excelExport, setExcelExport] = useState(false);
  //SEND USERS SECTION
  const addressBookList: AddressBook[] = useAppSelector(
    addressBookSelectors.selectAll
  );
  const [contactsValues, setContactsValues] = useState<DropDownItem[]>(
    [] as DropDownItem[]
  );
  const [contacts, setContacts] = useState<DropDownItem[]>([]);

  const reportSaved: ReportArchive =
    useAppSelector((state) =>
      reportsArchiveSelectors.selectById(state, schedulationId ?? 0)
    ) ?? ({} as ReportArchive);

  function headerTimeTitleBuilder() {
    if (startDate) {
      let tempPeriod = t(
        "reportSchedulation.modalSchedule." + timePeriod?.toLowerCase()
      );

      let tempStart =
        ", " +
        t("reportSchedulation.modalSchedule.startingFrom") +
        " " +
        startDate?.getDate() +
        " " +
        t(getMonthStringified(startDate?.getMonth())).slice(0, 3) +
        " " +
        startDate.getFullYear();

      let tempFrequency = "";

      if (timePeriod === "DAILY" && recursion) {
        tempFrequency =
          ", " +
          t("reportSchedulation.modalSchedule.every").split(" ")[0] +
          " " +
          recursion +
          " " +
          t("reportSchedulation.modalSchedule.days");
      }

      if (timePeriod === "MONTHLY") {
        if (
          timeFrequency === "EVERY" &&
          everyTimeDay?.length > 0 &&
          everyTimeMonth?.length > 0
        ) {
          tempFrequency =
            ", " +
            t("reportSchedulation.modalSchedule.every").replace(
              "*day",
              t(
                "reportSchedulation.modalSchedule.weekTime." +
                  everyTimeMonth[0].value
              ) +
                " " +
                t(
                  getDayOfWeek(
                    everyTimeDay[0].value ? everyTimeDay[0].value : 1
                  )
                )
            ) +
            " " +
            startDate.getHours().toString() +
            ":" +
            startDate.getMinutes().toString();
        }
        if (timeFrequency === "DAYS" && dayMonths?.length > 0) {
          tempFrequency =
            ", " +
            dayMonths[0].value +
            " " +
            t("reportSchedulation.modalSchedule.days");
        }
      }

      if (timePeriod === "WEEKLY" && day && recursion) {
        tempFrequency =
          ", " +
          t("reportSchedulation.modalSchedule.every").split(" ")[0] +
          " " +
          recursion +
          " " +
          t("reportSchedulation.modalSchedule.weeks") +
          " " +
          t("reportSchedulation.modalSchedule.daysNumber." + day);
      }

      return tempPeriod + tempStart + tempFrequency;
    }
  }

  function canBeScheduled() {
    let toBeSaved = true;
    if (name === "") {
      toBeSaved = false;
    }
    if (timePeriod === "DAILY" || timePeriod === "WEEKLY") {
      if (recursion === "" || recursion === "0") {
        toBeSaved = false;
      }
    }
    if (timePeriod === "MONTHLY") {
      if (monthsSelected?.length === 0 || monthsSelected === undefined) {
        toBeSaved = false;
      }
      if (timeFrequency === "DAYS") {
        if (dayMonths?.length === 0 || dayMonths === undefined) {
          toBeSaved = false;
        }
      }
      if (timeFrequency === "EVERY") {
        if (
          everyTimeDay?.length === 0 ||
          everyTimeMonth?.length === 0 ||
          everyTimeDay === undefined ||
          everyTimeMonth === undefined
        ) {
          toBeSaved = false;
        }
      }
    }
    if (pdfExport === false && excelExport === false) {
      toBeSaved = false;
    }
    if (contacts.length === 0) {
      toBeSaved = false;
    }
    if (customEntities && customEntitiesSelected.length === 0) {
      toBeSaved = false;
    }
    if (customEntitiesTwo && customEntitiesTwoSelected.length === 0) {
      toBeSaved = false;
    }
    return toBeSaved;
  }

  function resetAll() {
    setContacts([]);
    setExcelExport(false);
    setPdfExport(false);
    setTimePeriod("DAILY");
    setTimeFrequency("DAYS");
    setRecursion("");
    setDay(1);
    setMonthsSelected(undefined);
    setDayMonths(undefined);
    setEveryTimeMonth(undefined);
    setEveryTimeDay(undefined);
    setStartDate(new Date());
    setCustomEntitiesSelected([]);
    setCustomEntitiesTwoSelected([]);
    setName("");
    setResetKey((prevKey) => prevKey + 1);
  }

  function schedulate() {
    let reportExportTypes = [];
    if (pdfExport) {
      reportExportTypes.push("PDF");
    }
    if (excelExport) {
      reportExportTypes.push("XLXS");
    }

    const filteredQuery = {} as QueryParams;

    paramsToSchedulate?.map(
      (param: any) => (filteredQuery[param] = params[param])
    );

    if (customEntitiesParamName) {
      if (!filteredQuery[customEntitiesParamName]) {
        filteredQuery[customEntitiesParamName] = [];
      }
      customEntitiesSelected.forEach((entity) => {
        filteredQuery[customEntitiesParamName].push(entity.id);
      });
    }

    if (customEntitiesTwoParamName) {
      if (!filteredQuery[customEntitiesTwoParamName]) {
        filteredQuery[customEntitiesTwoParamName] = [];
      }
      customEntitiesTwoSelected.forEach((entity) => {
        filteredQuery[customEntitiesTwoParamName].push(entity.id);
      });
    }

    let cronoExpression = {} as CronExpressionRequest;
    if (timePeriod === "DAILY") {
      cronoExpression = {
        dayOfMonth: 0,
        daysOfWeek: [],
        months: [],
        recurrence: parseInt(recursion),
        reportPeriodEnum: "DAILY",
        weekSequence: "FIRST",
      };
    }
    if (timePeriod === "WEEKLY") {
      cronoExpression = {
        dayOfMonth: 0,
        daysOfWeek: [day - 1],
        months: [],
        recurrence: parseInt(recursion),
        reportPeriodEnum: "WEEKLY",
        weekSequence: "FIRST",
      };
    }
    if (timePeriod === "MONTHLY") {
      cronoExpression = {
        dayOfMonth:
          timeFrequency === "DAYS"
            ? dayMonths
              ? dayMonths[0]?.value
              : null
            : null,
        daysOfWeek:
          timeFrequency === "EVERY"
            ? Array.isArray(everyTimeDay)
              ? [everyTimeDay[0]?.value]
              : [everyTimeDay?.value]
            : [],

        months: monthsSelected?.map((month: any) => {
          return month.value;
        }),
        recurrence: parseInt(recursion),
        reportPeriodEnum: "MONTHLY",
        weekSequence:
          timeFrequency === "EVERY"
            ? everyTimeMonth
              ? everyTimeMonth[0]?.value
                ? weekSequenceArray[everyTimeMonth[0]?.value]
                : ("FIRST" as any)
              : null
            : null,
      };
    }

    const newSchedulation = {
      name: name,
      cronExpressionRequest: cronoExpression,
      startDate: startDate,
      addressBookIds: contacts.map((contact) => {
        return contact.id;
      }),
      reportTypes: reportExportTypes,
      parameters: getQueryString(filteredQuery),
      type: reportType,
    } as ReportSchedulateRequest;
    !schedulationId &&
      store
        .dispatch(createNewSchedulationAsync({ object: newSchedulation }))
        .then((response) => {
          response.meta.requestStatus === "fulfilled"
            ? ToastNotification({
                toastId: "schedulationCreated",
                status: "success",
                description: t("report.toastNotification.schedulationCreated"),
              })
            : ToastNotification({
                toastId: "schedulationError",
                status: "error",
                description: t("common.networkError"),
              });

          if (response) {
            closeAndNavigate();
          }
        });
    schedulationId &&
      store
        .dispatch(
          updateReportSchedulationAsync({
            object: newSchedulation,
            schedulationId: schedulationId,
          })
        )
        .then((response) => {
          response.meta.requestStatus === "fulfilled"
            ? ToastNotification({
                toastId: "schedulationModified",
                status: "success",
                description: t("report.toastNotification.schedulationModified"),
              })
            : ToastNotification({
                toastId: "schedulationError",
                status: "error",
                description: t("common.networkError"),
              });

          if (response) {
            closeAndNavigate();
          }
        });
  }

  //USEEFFECT REGION
  useEffect(() => {
    schedulationId &&
      store.dispatch(
        getReportScheduledAsync({ scheduledReportId: schedulationId })
      );

    return () => {
      sessionStorage.removeItem("pageReloaded");
    };
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(reportSaved) &&
      (customEntitiesTwo?.length
        ? customEntitiesTwo.length > 0 && customEntities?.length > 0
        : customEntities?.length > 0) &&
      name === ""
    ) {
      const schedulationParameters = new URLSearchParams(
        reportSaved.parameters
      );
      const url = new URL(window.location.href);
      const hasPageReloaded = sessionStorage.getItem("pageReloaded");

      if (!hasPageReloaded) {
        paramsToSchedulate?.map((param: any) => {
          let tempParam = schedulationParameters.getAll(param);
          tempParam.map((par) => {
            url.searchParams.set(param, par);
          });
        });

        if (paramsToSchedulate?.length > 0) {
          window.history.replaceState(null, "", url.toString());
          sessionStorage.setItem("pageReloaded", "true");
          window.location.reload();
        }
      }

      let tempCustomEntitiesSelected: DropDownItem[] = [];
      let tempCustomEntitiesTwoSelected: DropDownItem[] = [];
      let tempContactSelected: DropDownItem[] = [];
      let tempCustomEntities = schedulationParameters.getAll(
        customEntitiesParamName ?? ""
      );
      customEntities?.map((entity) => {
        if (tempCustomEntities.includes(entity?.id?.toString() ?? "")) {
          tempCustomEntitiesSelected.push({
            id: entity.id,
            title: entity.title,
          });
        }
      });

      let tempCustomEntitiesTwo = schedulationParameters.getAll(
        customEntitiesTwoParamName ?? ""
      );
      customEntitiesTwo?.map((entity) => {
        if (tempCustomEntitiesTwo.includes(entity?.id?.toString() ?? "")) {
          tempCustomEntitiesTwoSelected.push({
            id: entity.id,
            title: entity.title,
          });
        }
      });

      let tempEmails = schedulationParameters.getAll("emails");
      addressBookList.map((addressBook) => {
        if (tempEmails.includes(addressBook.email)) {
          tempContactSelected.push({
            title: addressBook.firstName + " " + addressBook.lastName,
            id: addressBook.id,
          });
        }
      });

      setExcelExport(
        schedulationParameters.get("isXlxs") === "true" ? true : false
      );
      setPdfExport(
        schedulationParameters.get("isPdf") === "true" ? true : false
      );

      setRecursion(reportSaved.cronExpressionModule?.recurrence?.toString());
      setTimePeriod(
        reportSaved.cronExpressionModule?.reportPeriodEnum ?? "DAILY"
      );

      let tempDaysOfWeek = convertDaysOfWeek(
        reportSaved.cronExpressionModule?.daysOfWeek
      ) as number[];
      let tempMonths = convertMonths(
        reportSaved.cronExpressionModule?.months
      ) as number[];

      !!tempDaysOfWeek &&
        tempDaysOfWeek.length > 0 &&
        setDay(tempDaysOfWeek[0] + 1);

      setMonthsSelected(
        tempMonths?.map((month: number) => {
          return {
            value: month,
            label: t(getMonthStringified(month)),
            checked: true,
          } as any;
        })
      );

      reportSaved.cronExpressionModule?.dayOfMonth &&
        setDayMonths({
          value: reportSaved.cronExpressionModule?.dayOfMonth,
          label: reportSaved.cronExpressionModule?.dayOfMonth,
          checked: true,
        });

      tempDaysOfWeek.length > 0 &&
        setEveryTimeDay({
          value: tempDaysOfWeek[0],
          label: t(
            "reportSchedulation.modalSchedule.daysNumber." + tempDaysOfWeek[0]
          ),
        });
      reportSaved.cronExpressionModule?.weekSequence &&
        setEveryTimeMonth({
          value:
            WeekSequenceEnumToNumber[
              reportSaved.cronExpressionModule?.weekSequence
            ],
          label: t(
            "reportSchedulation.modalSchedule.weekTime." +
              WeekSequenceEnumToNumber[
                reportSaved.cronExpressionModule?.weekSequence
              ]
          ),
          checked: true,
        });

      if (reportSaved.cronExpressionModule?.weekSequence) {
        setTimeFrequency("EVERY");
      } else {
        setTimeFrequency("DAYS");
      }

      setStartDate(new Date(reportSaved?.startDate));
      setContacts(tempContactSelected);
      setCustomEntitiesSelected(tempCustomEntitiesSelected);
      setCustomEntitiesTwoSelected(tempCustomEntitiesTwoSelected);
      setName(reportSaved.name);
      setResetKey((prevKey) => prevKey + 1);
    }
  }, [reportSaved, customEntities, customEntitiesTwo, addressBookList]);

  useEffect(() => {
    if (addressBookList.length > 0) {
      const contactsValueTemp = addressBookList.map((addressBook) => {
        return {
          title: addressBook.firstName + " " + addressBook.lastName,
          id: addressBook.id,
        };
      });
      setContactsValues(contactsValueTemp);
    }
  }, [addressBookList]);

  function closeAndNavigate() {
    onClose();
    store.dispatch(reportsArchiveEmptyState());
    navigate("/reports/" + getReportRoute(reportType));
  }

  return (
    <>
      <div className="schedulation-modal">
        <ModalWrapper open={isOpen} closeAction={closeAndNavigate}>
          <ModalBody
            title={
              t("reportSchedulation.modalSchedule.title") +
              t("reports.types." + reportType)
            }
            desc={t("reportSchedulation.modalSchedule.description")}
            stylePadding={"0px"}
          >
            <div
              style={{
                maxHeight: "500px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {/* NAME REGION */}
              <div className="schedulation-modal-card">
                {/* HEADER */}
                <div className="schedulation-modal-card-header">
                  <div className="schedulation-modal-card-header-section">
                    <span className="schedulation-modal-card-circle-icon">
                      <IconEdit size={14} />
                    </span>
                    {elementOpened === "NAME" || name === ""
                      ? t("reportSchedulation.modalSchedule.scheduleName")
                      : name}
                  </div>
                  <div className="schedulation-modal-card-header-section">
                    {elementOpened === "NAME" && (
                      <span
                        style={{
                          color: "#00AAFF",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => setName("")}
                      >
                        reset
                      </span>
                    )}
                    {elementOpened === "NAME" ? (
                      <span style={{ cursor: "pointer" }}>
                        <IconMinus
                          size={14}
                          onClick={() => setElementOpened("")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer" }}>
                        <IconAdd
                          size={14}
                          onClick={() => setElementOpened("NAME")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                {/* BODY */}
                {elementOpened === "NAME" && (
                  <div className="schedulation-modal-card-body">
                    <div
                      style={{
                        marginTop: "30px",
                        marginLeft: "70px",
                        marginBottom: "20px",
                      }}
                    >
                      <Form>
                        <TextField
                          size="small"
                          id="Description"
                          type="text"
                          events={{ onChange: (data) => setName(data.value) }}
                          value={name}
                          placeholder={""}
                        ></TextField>
                      </Form>
                    </div>
                  </div>
                )}
              </div>
              {/* CHILDREN CUSTOMIZED FIRST SECTION REGION */}
              <div className="schedulation-modal-card">
                {/* HEADER */}
                <div className="schedulation-modal-card-header">
                  <div className="schedulation-modal-card-header-section">
                    <span className="schedulation-modal-card-circle-icon">
                      {customIcon}
                    </span>
                    {elementOpened === "CHILDREN"
                      ? customTextLabel
                      : customEntitiesSelected?.length > 0
                      ? customEntitiesSelected?.map((entity, index) => {
                          return (
                            entity.title +
                            (index === customEntitiesSelected.length - 1
                              ? ""
                              : ", ")
                          );
                        })
                      : customTextLabel}
                  </div>
                  <div className="schedulation-modal-card-header-section">
                    {elementOpened === "CHILDREN" && (
                      <span
                        style={{
                          color: "#00AAFF",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => (
                          setCustomEntitiesSelected([]),
                          setResetKey((prevKey) => prevKey + 1)
                        )}
                      >
                        reset
                      </span>
                    )}
                    {elementOpened === "CHILDREN" ? (
                      <span style={{ cursor: "pointer" }}>
                        <IconMinus
                          size={14}
                          onClick={() => setElementOpened("")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer" }}>
                        <IconAdd
                          size={14}
                          onClick={() => setElementOpened("CHILDREN")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                {/* BODY */}
                {elementOpened === "CHILDREN" && (
                  <div className="schedulation-modal-card-body">
                    <div style={{ marginTop: "20px", marginLeft: "70px" }}>
                      <Form>
                        <TextFieldDropdown
                          key={resetKey}
                          hasDropdown={false}
                          hasUserBox={false}
                          getValues={(e) => setCustomEntitiesSelected(e)}
                          values={customEntities}
                          defaultValues={customEntitiesSelected}
                          placeholder={customTextLabel?.toString()}
                        />
                      </Form>
                    </div>
                  </div>
                )}
              </div>
              {/* CHILDREN CUSTOMIZED SECOND SECTION REGION */}
              {customEntitiesTwo && (
                <div className="schedulation-modal-card">
                  {/* HEADER */}
                  <div className="schedulation-modal-card-header">
                    <div className="schedulation-modal-card-header-section">
                      <span className="schedulation-modal-card-circle-icon">
                        {customIconTwo}
                      </span>
                      {elementOpened === "SECONDCHILDREN"
                        ? customTextLabelTwo
                        : customEntitiesTwoSelected?.length > 0
                        ? customEntitiesTwoSelected?.map((entity, index) => {
                            return (
                              entity.title +
                              (index === customEntitiesTwoSelected.length - 1
                                ? ""
                                : ", ")
                            );
                          })
                        : customTextLabelTwo}
                    </div>
                    <div className="schedulation-modal-card-header-section">
                      {elementOpened === "SECONDCHILDREN" && (
                        <span
                          style={{
                            color: "#00AAFF",
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => (
                            setCustomEntitiesTwoSelected([]),
                            setResetKey((prevKey) => prevKey + 1)
                          )}
                        >
                          reset
                        </span>
                      )}
                      {elementOpened === "SECONDCHILDREN" ? (
                        <span style={{ cursor: "pointer" }}>
                          <IconMinus
                            size={14}
                            onClick={() => setElementOpened("")}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      ) : (
                        <span style={{ cursor: "pointer" }}>
                          <IconAdd
                            size={14}
                            onClick={() => setElementOpened("SECONDCHILDREN")}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  {/* BODY */}
                  {elementOpened === "SECONDCHILDREN" && (
                    <div className="schedulation-modal-card-body">
                      <div style={{ marginTop: "20px", marginLeft: "70px" }}>
                        <Form>
                          <TextFieldDropdown
                            key={resetKey}
                            hasDropdown={false}
                            hasUserBox={false}
                            getValues={(e) => setCustomEntitiesTwoSelected(e)}
                            values={customEntitiesTwo}
                            defaultValues={customEntitiesTwoSelected}
                            placeholder={customTextLabelTwo?.toString()}
                          />
                        </Form>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* CHILDREN CUSTOMIZED PARAMS SECTION REGION */}
              {paramsComponents && (
                <div className="schedulation-modal-card">
                  {/* HEADER */}
                  <div className="schedulation-modal-card-header">
                    <div className="schedulation-modal-card-header-section">
                      <span className="schedulation-modal-card-circle-icon">
                        {<IconBuild size={14} />}
                      </span>
                      {t("reportSchedulation.modalSchedule.selectParams")}
                    </div>
                    <div className="schedulation-modal-card-header-section">
                      {elementOpened === "PARAMSCOMPONENTS" ? (
                        <span style={{ cursor: "pointer" }}>
                          <IconMinus
                            size={14}
                            onClick={() => setElementOpened("")}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      ) : (
                        <span style={{ cursor: "pointer" }}>
                          <IconAdd
                            size={14}
                            onClick={() => setElementOpened("PARAMSCOMPONENTS")}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  {/* BODY */}
                  {elementOpened === "PARAMSCOMPONENTS" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "20px",
                        gap: "10px",
                        marginLeft: "70px",
                        position: "relative",
                      }}
                    >
                      {paramsComponents.map((param) => {
                        return param;
                      })}
                    </div>
                  )}
                </div>
              )}
              {/* PERIOD REGION */}
              <div className="schedulation-modal-card">
                {/* HEADER */}
                <div className="schedulation-modal-card-header">
                  <div className="schedulation-modal-card-header-section">
                    <span className="schedulation-modal-card-circle-icon">
                      <IconCalendar size={14} />
                    </span>
                    {elementOpened === "TIME"
                      ? t("reportSchedulation.modalSchedule.selectPeriod")
                      : startDate
                      ? headerTimeTitleBuilder()
                      : t("reportSchedulation.modalSchedule.selectPeriod")}
                  </div>
                  <div className="schedulation-modal-card-header-section">
                    {elementOpened === "TIME" && (
                      <span
                        style={{
                          color: "#00AAFF",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setTimePeriod("DAILY");
                          setTimeFrequency("DAYS");
                          setRecursion("");
                          setDay(1);
                          setMonthsSelected(undefined);
                          setDayMonths(undefined);
                          setEveryTimeMonth(undefined);
                          setEveryTimeDay(undefined);
                          setStartDate(new Date());
                        }}
                      >
                        reset
                      </span>
                    )}
                    {elementOpened === "TIME" ? (
                      <span style={{ cursor: "pointer" }}>
                        <IconMinus
                          size={14}
                          onClick={() => setElementOpened("")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer" }}>
                        <IconAdd
                          size={14}
                          onClick={() => setElementOpened("TIME")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                {/* BODY */}
                {elementOpened === "TIME" && (
                  <div className="schedulation-modal-card-body">
                    <div
                      style={{
                        marginTop: "30px",
                        marginLeft: "70px",
                        marginRight: "40px",
                        marginBottom: "20px",
                      }}
                    >
                      {/* SELECTOR REGION */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          marginBottom: "20px",
                        }}
                      >
                        <Radio
                          checked={timePeriod === "DAILY"}
                          onChange={() => {
                            setTimePeriod("DAILY");
                          }}
                          label={t("reportSchedulation.modalSchedule.daily")}
                        />
                        <Radio
                          checked={timePeriod === "WEEKLY"}
                          onChange={() => {
                            setTimePeriod("WEEKLY");
                          }}
                          label={t("reportSchedulation.modalSchedule.weekly")}
                        />
                        <Radio
                          checked={timePeriod === "MONTHLY"}
                          onChange={() => {
                            setTimePeriod("MONTHLY");
                          }}
                          label={t("reportSchedulation.modalSchedule.monthly")}
                        />
                      </div>
                      {/* SELECTOR endREGION */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          gap: "20px",
                          marginTop: "20px",
                        }}
                      >
                        <div>
                          <div
                            className="schedulation-modal-card-text-sub"
                            style={{
                              paddingLeft: "10px",
                            }}
                          >
                            {t("reportSchedulation.modalSchedule.startFrom")}
                          </div>
                          <Form>
                            <SingleDatePicker
                              id="1"
                              size="small"
                              validation=""
                              setDate={(date) => {
                                !isString(date) && setStartDate(date.toDate());
                              }}
                              hasTime={true}
                              oldDate={startDate?.toString()}
                              setErrorList={setErrorList}
                              errorList={errorList}
                              dateRange={[dayjs(), dayjs().add(100, "year")]}
                              placeholder={
                                "                                       "
                              }
                              localeFormat={"YYYY-MM-DD HH:mm"}
                              language={preferencesContext.language ?? "it"}
                            />
                          </Form>
                        </div>
                        {/*FRENQUENCY AND MONTHS REGION*/}
                        <div>
                          <div
                            className="schedulation-modal-card-text-sub"
                            style={{ paddingLeft: "10px" }}
                          >
                            {timePeriod !== "MONTHLY"
                              ? t("reportSchedulation.modalSchedule.recurs")
                              : t("reportSchedulation.modalSchedule.months")}
                          </div>
                          {timePeriod !== "MONTHLY" ? (
                            <Form>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <input
                                  className="schedulation-recursion-input"
                                  style={{
                                    borderRadius: "4px",
                                    height: "32px",
                                    padding: "13px",
                                    paddingLeft: "16px",
                                  }}
                                  key={resetKey}
                                  id="Number"
                                  type="text"
                                  onChange={(data) => {
                                    var reg = new RegExp("^[0-9]+$");
                                    if (
                                      reg.test(data.target.value) ||
                                      data.target.value === ""
                                    ) {
                                      setRecursion(data.target.value);
                                    }
                                  }}
                                  value={recursion}
                                  placeholder={
                                    timePeriod === "DAILY"
                                      ? t(
                                          "reportSchedulation.modalSchedule.days"
                                        )
                                      : t(
                                          "reportSchedulation.modalSchedule.week"
                                        )
                                  }
                                ></input>
                                {timePeriod === "WEEKLY" &&
                                  t("reportSchedulation.modalSchedule.on")}
                              </div>
                            </Form>
                          ) : (
                            <Dropdown
                              hasCheckbox={true}
                              hasSelectAll={true}
                              placeholder={t(
                                "reportSchedulation.modalSchedule.months"
                              )}
                              options={Array(12)
                                .fill(0)
                                .map((value, index) => {
                                  return {
                                    value: index,
                                    label: t(getMonthStringified(index)),
                                    checked: false,
                                  } as any;
                                })}
                              size="small"
                              itemAttribute={"label"}
                              value={monthsSelected}
                              onChange={(el) => setMonthsSelected(el)}
                            />
                          )}
                        </div>
                        {/*FRENQUENCY AND MONTHS endREGION*/}
                      </div>
                      {/*WEEK DAYS RADIO BUTTON REGION*/}
                      {timePeriod === "WEEKLY" && (
                        <div className="schedulation-modal-radio">
                          <div
                            className="schedulation-modal-card-radio-area"
                            style={{ marginTop: "20px" }}
                          >
                            <Radio
                              checked={day === 1}
                              onChange={() => {
                                setDay(1);
                              }}
                              label={t("common.datePicker.days.Monday")}
                            />
                            <Radio
                              checked={day === 2}
                              onChange={() => {
                                setDay(2);
                              }}
                              label={t("common.datePicker.days.Tuesday")}
                            />
                            <Radio
                              checked={day === 3}
                              onChange={() => {
                                setDay(3);
                              }}
                              label={t("common.datePicker.days.Wednesday")}
                            />
                            <Radio
                              checked={day === 4}
                              onChange={() => {
                                setDay(4);
                              }}
                              label={t("common.datePicker.days.Thursday")}
                            />
                          </div>
                          <div
                            className="schedulation-modal-card-radio-area"
                            style={{ marginTop: "20px", marginRight: "70px" }}
                          >
                            <Radio
                              checked={day === 5}
                              onChange={() => {
                                setDay(5);
                              }}
                              label={t("common.datePicker.days.Friday")}
                            />
                            <Radio
                              checked={day === 6}
                              onChange={() => {
                                setDay(6);
                              }}
                              label={t("common.datePicker.days.Saturday")}
                            />
                            <Radio
                              checked={day === 7}
                              onChange={() => {
                                setDay(7);
                              }}
                              label={t("common.datePicker.days.Sunday")}
                            />
                          </div>
                        </div>
                      )}
                      {/*WEEK DAYS RADIO BUTTON endREGION*/}
                      {/*MONTHLY REGION */}
                      {timePeriod == "MONTHLY" && (
                        <div className="schedulation-modal-card-monthly-section">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              marginTop: "20px",
                              width: "200px",
                              marginRight: "40px",
                            }}
                          >
                            <Radio
                              checked={timeFrequency === "DAYS"}
                              onChange={() => {
                                setTimeFrequency("DAYS");
                              }}
                              label={t("reportSchedulation.modalSchedule.days")}
                            />
                            <Radio
                              checked={timeFrequency === "EVERY"}
                              onChange={() => {
                                setTimeFrequency("EVERY");
                              }}
                              label={
                                t(
                                  "reportSchedulation.modalSchedule.every"
                                ).split(" ")[0]
                              }
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              marginTop: "20px",
                            }}
                          >
                            <Dropdown
                              size="small"
                              isDisabled={timeFrequency !== "DAYS"}
                              options={Array(31)
                                .fill(0)
                                .map((value, index) => {
                                  return {
                                    value: index,
                                    label: index,
                                    checked: false,
                                  };
                                })}
                              itemAttribute={"label"}
                              placeholder={t(
                                "reportSchedulation.modalSchedule.selectDays"
                              )}
                              value={dayMonths}
                              hasRadio={true}
                              onChange={(e) => {
                                setDayMonths(e);
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px",
                              }}
                            >
                              <Dropdown
                                size="small"
                                isDisabled={timeFrequency !== "EVERY"}
                                onChange={(e) => {
                                  setEveryTimeMonth(e);
                                }}
                                hasRadio={true}
                                placeholder={t(
                                  "reportSchedulation.modalSchedule.selectWeek"
                                )}
                                value={everyTimeMonth}
                                options={Array(5)
                                  .fill(0)
                                  .map((value, index) => {
                                    return {
                                      value: index + 1,
                                      label: t(
                                        "reportSchedulation.modalSchedule.weekTime." +
                                          (index + 1)
                                      ),
                                      checked: false,
                                    };
                                  })}
                                itemAttribute={"label"}
                              />
                              <Dropdown
                                size="small"
                                isDisabled={timeFrequency !== "EVERY"}
                                onChange={(e) => {
                                  setEveryTimeDay(e);
                                }}
                                placeholder={t(
                                  "reportSchedulation.modalSchedule.selectDays"
                                )}
                                hasRadio={true}
                                options={Array(7)
                                  .fill(0)
                                  .map((el, index) => {
                                    return {
                                      value: index + 1,
                                      label: t(
                                        "reportSchedulation.modalSchedule.daysNumber." +
                                          (index + 1)
                                      ),
                                    };
                                  })}
                                value={everyTimeDay}
                                itemAttribute={"label"}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {/*MONTHLY endREGION */}
                    </div>
                  </div>
                )}
              </div>
              {/* TYPE REGION */}
              <div className="schedulation-modal-card">
                {/* HEADER */}
                <div className="schedulation-modal-card-header">
                  <div className="schedulation-modal-card-header-section">
                    <span className="schedulation-modal-card-circle-icon">
                      <IconAttach size={14} />
                    </span>
                    {elementOpened === "TYPE"
                      ? t("reportSchedulation.modalSchedule.reportType")
                      : pdfExport || excelExport
                      ? (pdfExport ? "PDF" : "") +
                        " " +
                        (excelExport ? "EXCEL" : "")
                      : t("reportSchedulation.modalSchedule.reportType")}
                  </div>
                  <div className="schedulation-modal-card-header-section">
                    {elementOpened === "TYPE" && (
                      <span
                        style={{
                          color: "#00AAFF",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => (
                          setExcelExport(false), setPdfExport(false)
                        )}
                      >
                        reset
                      </span>
                    )}
                    {elementOpened === "TYPE" ? (
                      <span style={{ cursor: "pointer" }}>
                        <IconMinus
                          size={14}
                          onClick={() => setElementOpened("")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer" }}>
                        <IconAdd
                          size={14}
                          onClick={() => setElementOpened("TYPE")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                {/* BODY */}
                {elementOpened === "TYPE" && (
                  <div className="schedulation-modal-card-body">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: "70px",
                        marginRight: "140px",
                        marginTop: "20px",
                        marginBottom: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        label="PDF"
                        onChange={() => setPdfExport(!pdfExport)}
                        checked={pdfExport}
                      />
                      <Checkbox
                        label="Excel"
                        onChange={() => setExcelExport(!excelExport)}
                        checked={excelExport}
                      />
                    </div>
                  </div>
                )}
              </div>
              {/* USERS REGION */}
              <div className="schedulation-modal-card">
                {/* HEADER */}
                <div className="schedulation-modal-card-header">
                  <div className="schedulation-modal-card-header-section">
                    <span className="schedulation-modal-card-circle-icon">
                      <User size={14} />
                    </span>
                    {elementOpened === "USERS"
                      ? t("reportSchedulation.modalSchedule.sendTo")
                      : contacts?.length && contacts?.length > 0
                      ? contacts.map((e, index) => {
                          return (
                            e.title.toLocaleUpperCase() +
                            (index === contacts.length - 1 ? "" : ", ")
                          );
                        })
                      : t("reportSchedulation.modalSchedule.sendTo")}
                  </div>
                  <div className="schedulation-modal-card-header-section">
                    {elementOpened === "USERS" && (
                      <span
                        style={{
                          color: "#00AAFF",
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setContacts([]);
                          setResetKey((prevKey) => prevKey + 1);
                        }}
                      >
                        reset
                      </span>
                    )}
                    {elementOpened === "USERS" ? (
                      <span style={{ cursor: "pointer" }}>
                        <IconMinus
                          size={14}
                          onClick={() => setElementOpened("")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    ) : (
                      <span style={{ cursor: "pointer" }}>
                        <IconAdd
                          size={14}
                          onClick={() => setElementOpened("USERS")}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    )}
                  </div>
                </div>
                {/* BODY */}
                {elementOpened === "USERS" && (
                  <div className="schedulation-modal-card-body">
                    {
                      <div style={{ marginTop: "20px", marginLeft: "70px" }}>
                        <TextFieldDropdown
                          key={resetKey}
                          id="contacts"
                          hasDropdown={false}
                          hasUserBox={false}
                          values={contactsValues ?? []}
                          placeholder={t("common.findContacts")}
                          getValues={(data) => {
                            setContacts(data);
                          }}
                          defaultValues={contacts}
                          hasAddContact={true}
                          handleAddContactClick={() =>
                            setAddressBookModal(!addressBookModal)
                          }
                        />
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter
            primaryAction={() => schedulate()}
            secondaryAction={() => {
              closeAndNavigate();
            }}
            disablePrimaryButton={!canBeScheduled()}
            primaryLabel={
              schedulationId
                ? t("reportSchedulation.modalSchedule.saveAndExit")
                : t("reportSchedulation.modalSchedule.schedule")
            }
            secondaryLabel={t("common.cancel")}
            padding={"20px"}
          />
        </ModalWrapper>
        <AddressBookModals
          allComponents={true}
          open={addressBookModal}
          sections={[{ id: 0, name: "add-contact" }]}
          currentActive="add-contact"
          onClose={() => setAddressBookModal(!addressBookModal)}
        />
      </div>
    </>
  );
};
