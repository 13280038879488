import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../../utils/Utils";
import {
  notificationRuleAggregationSchema,
  notificationRuleAggregationsSchema,
} from "./adminNotificationNormalization";
import NotificationsRuleAggregationRepository from "./NotificationsRuleAggregationRepository";

export type NotificationRuleAggregation = {
  id: number;
  tenant: number;
  name: string;
  status: StatusType;
  type: NotificationRuleType;
  notificationRules: number[];
  notificationRulesEntities: NotificationRuleEntityDTO[];
};

export type NotificationRuleEntityDTO = {
  notificationRuleEntityType: string;
  notificationRuleEntityId: number;
};

export type NotificationTemplateDTO = {
  id: number;
  event: string;
  type: NotificationTemplateType;
  template: string;
  notificationRuleAggregationId: number;
};

export type NotificationRuleAggregationCreateRequest = {
  name: string;
  addressBookIdList: number[];
  eventTemplatesMap: { [key: string]: string };
  mumsChannelTypeEnums: ChannelType[];
  frequency: number;
  type: NotificationRuleType;
  entities: NotificationRuleEntityDTO[];
};

export type NotificationRuleAggregationUpdateRequest = {
  newName: string;
  newAddress: number[];
  removeAddress: number[];
  newChannel: ChannelType[];
  removeChannel: ChannelType[];
  newEventTemplatesMap: { [key: string]: string };
  removeEventNameEnums: string[];
  frequency: number;
  entities: NotificationRuleEntityDTO[];
};

export const NotificationRuleEntityTypeEnum = {
  VEHICLE: "VEHICLE",
  DRIVER: "DRIVER",
  GEOFENCE: "GEOFENCE",
  ASSET: "ASSET",
};

export const NotificationTemplateTypeEnum = {
  TEXT: "TEXT",
  HTML: "HTML",
};
export type NotificationTemplateType =
  keyof typeof NotificationTemplateTypeEnum;

export const NotificationRuleTypeEnum = {
  CUSTOM: "CUSTOM",
  SYSTEM: "SYSTEM",
};
export type NotificationRuleType = keyof typeof NotificationRuleTypeEnum;

export const NotificationStatusEnum = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};
export type StatusType = keyof typeof NotificationStatusEnum;

export const NotificationChannelEnum = {
  // SMS: "SMS",
  // EMAIL: "EMAIL",
  NOTIFICATION: "NOTIFICATION",
  // VOICECALL: "VOICECALL",
};
export type ChannelType = keyof typeof NotificationChannelEnum;

export const getFilteredNotificationsRuleAggregationsAsync = createAsyncThunk(
  "notificationRuleAggregations/getFilteredNotificationsRules",
  async (data: { queryParams?: string }, { dispatch, rejectWithValue }) => {
    try {
      const notificationRuleAggregationRepository =
        new NotificationsRuleAggregationRepository();
      const response =
        await notificationRuleAggregationRepository.getFilteredNotificationsRuleAggregations(
          data.queryParams
        );
      const filteredNotifications = _.get(
        response,
        Config.NOTIFICATION_RULE_PATH
      );
      const totalPages = _.get(
        response,
        Config.NOTIFICATION_RULE_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.NOTIFICATION_RULE_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const normalizedData = normalize(
        filteredNotifications ?? [],
        notificationRuleAggregationsSchema
      );
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

// Supponiamo che questi siano già definiti da qualche parte

export const getNotificationRule = createAsyncThunk(
  "notifications/getNotificationRule",
  async (data: { aggregationRuleId: number }) => {
    const notificationRuleRepository =
      new NotificationsRuleAggregationRepository();

    // Ottieni la regola di notifica
    const response = await notificationRuleRepository.getNotificationRule(
      data.aggregationRuleId
    );

    // Estrai la parte rilevante della risposta
    const notificationRuleAggregation = _.get(
      response,
      Config.NOTIFICATION_RULE_AGGREGATION_PATH
    );

    // Normalizza i dati usando lo schema definito
    const normalizedData = normalize(
      notificationRuleAggregation ?? [],
      notificationRuleAggregationSchema
    );

    // Restituisce gli oggetti normalizzati
    return normalizedData.entities;
  }
);

export const createNotificationRuleAsync = createAsyncThunk(
  "notifications/createRule",
  async (
    data: {
      notificationRuleAggregation: NotificationRuleAggregationCreateRequest;
    },
    { rejectWithValue }
  ) => {
    try {
      const adminNotificationRepository =
        new NotificationsRuleAggregationRepository();
      const response =
        await adminNotificationRepository.createAdminNotification(
          data.notificationRuleAggregation
        );
      const notificationRuleAggregation = _.get(
        response,
        Config.NOTIFICATION_RULE_AGGREGATION_PATH
      );
      const normalizedData = normalize(
        notificationRuleAggregation ?? [],
        notificationRuleAggregationSchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response);
    }
  }
);

export const editNotificationRuleAsync = createAsyncThunk(
  "notifications/editRule",
  async (
    data: {
      notificationRuleAggregation: NotificationRuleAggregationUpdateRequest;
      aggregationRuleId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const adminNotificationRepository =
        new NotificationsRuleAggregationRepository();
      const response = await adminNotificationRepository.editAdminNotification(
        data.notificationRuleAggregation,
        data.aggregationRuleId
      );
      const notificationRule = _.get(
        response,
        Config.NOTIFICATION_RULE_AGGREGATION_PATH
      );
      const normalizedData = normalize(
        notificationRule,
        notificationRuleAggregationSchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "notification/updateStatus",
  async (
    data: { notificationRuleId: number; status: string },
    { rejectWithValue }
  ) => {
    try {
      const adminNotificationsRepository =
        new NotificationsRuleAggregationRepository();
      const response = await adminNotificationsRepository.updateStatus(
        data.notificationRuleId,
        data.status
      );
      const notification = _.get(response, Config.NOTIFICATION_RULE_PATCH_PATH);
      const normalizedData = normalize(
        notification,
        notificationRuleAggregationSchema
      );

      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const deleteNotificationRuleAggregationAsync = createAsyncThunk(
  "notificationRuleAggregations/deleteNotificationRuleAggregation",
  async (notificationAggregationRuleId: number, { rejectWithValue }) => {
    try {
      const notificationRuleAggregationRepository =
        new NotificationsRuleAggregationRepository();
      await notificationRuleAggregationRepository.deleteNotificationsRuleAggregations(
        notificationAggregationRuleId
      );
      return notificationAggregationRuleId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

function handleFailedData(state: any, action: PayloadAction<any>) {
  let castAction: any = action;
  if (castAction?.payload?.data?.message) {
    state.reasonCode = getErrorCodes(castAction?.payload?.data?.message);
  }
  if (castAction?.payload?.status && castAction?.payload?.status === 500) {
    state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
  }
}

function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.notificationRuleAggregation
    ? notitficationRuleAggregationsAdapter.setAll(
        state,
        action.payload.notificationRuleAggregation
      )
    : notitficationRuleAggregationsAdapter.setAll(state, []);
}

const notitficationRuleAggregationsAdapter =
  createEntityAdapter<NotificationRuleAggregation>({
    selectId: (notitficationRule) => notitficationRule.id,
  });

export const notitficationRuleAggregationSlice = createSlice({
  name: "notificationRuleAggregations",
  initialState: notitficationRuleAggregationsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    notificationsRuleAggregationEmptyState: (state: any) => {
      notitficationRuleAggregationsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        updateStatus.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notitficationRuleAggregationsAdapter.upsertMany(
            state,
            action.payload.notificationRuleAggregation
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        updateStatus.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      )
      .addCase(
        getNotificationRule.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getNotificationRule.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getNotificationRule.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getFilteredNotificationsRuleAggregationsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredNotificationsRuleAggregationsAsync.pending,
        (state: any) => {
          state.status = "loading";
        }
      )
      .addCase(
        getFilteredNotificationsRuleAggregationsAsync.rejected,
        (state: any) => {
          state.status = "failed";
          state.reasonCode = "";
        }
      )
      .addCase(
        deleteNotificationRuleAggregationAsync.fulfilled,
        (state: any, action: PayloadAction<number>) => {
          notitficationRuleAggregationsAdapter.removeOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.DELETE;
        }
      )
      .addCase(
        deleteNotificationRuleAggregationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      )
      .addCase(deleteNotificationRuleAggregationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        createNotificationRuleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notitficationRuleAggregationsAdapter.upsertMany(
            state,
            action.payload.notificationRuleAggregation ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(
        createNotificationRuleAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      )
      .addCase(createNotificationRuleAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        editNotificationRuleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          notitficationRuleAggregationsAdapter.upsertMany(
            state,
            action.payload.notificationRuleAggregation
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PUT;
        }
      )
      .addCase(
        editNotificationRuleAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(editNotificationRuleAsync.pending, (state: any) => {
        state.status = "loading";
      });
  },
});

export const notificationRuleAggregationsSelectors =
  notitficationRuleAggregationsAdapter.getSelectors<RootState>(
    (state) => state.notificationRuleAggregations
  );
export const selectNotificationRuleAggregationSliceStatus = (state: any) =>
  state.notificationRuleAggregations.status;

export const selectNotificationRuleAggregationSliceReasonCode = (state: any) =>
  state.notificationRuleAggregations.reasonCode;

export const selectNotificationRuleAggregationSlicePage = (state: any) =>
  state.notificationRuleAggregations.totalPages;

export const selectNotificationRuleAggregationSliceTotalElements = (
  state: any
) => state.notificationRuleAggregations.totalElements;

export const {
  notificationsRuleAggregationEmptyState,
  setNumberOfPages,
  setNumberOfElements,
} = notitficationRuleAggregationSlice.actions;

export default notitficationRuleAggregationSlice.reducer;
