import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconDrivers } from "../../ui/Icon/Line/Drivers";
import { IconFileReport } from "../../ui/Icon/Line/FileReport";
import { IconGeofence } from "../../ui/Icon/Line/Geofence";
import { IconLicense } from "../../ui/Icon/Line/License";
import { IconVehicle } from "../../ui/Icon/Line/Vehicle";
import { Report } from "../../ui/Reports/Report";
import { Reports } from "../../ui/Reports/Reports";
import { getQueryString, getReportRoute } from "../../utils/Utils";
import { FileArchiveModal } from "../archive/FileArchiveModal";
import {
  schedulesEmptyState,
  schedulesSelectors,
} from "../schedules/schedulesSlice";
import {
  getTachographFilesActivityReportAsync,
  getTachographFilesInfringementLetterReportAsync,
  selectTachographFilesSliceStatus,
  tachographFilesSelectors,
} from "../tachograph/tachographFilesSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import "./ReportView.css";
import {
  getAllScheduledReportAsync,
  selectReportArchiveStatusSlicTotalElements,
} from "./archive/reportArchiveSlice";
import {
  getReportsMetadataAsync,
  reportsMetadataEmptyState,
  reportsMetadataSelectors,
} from "./reportsMetadataSlice";

const reportArchiveTypeValues = {
  DAILY_ACTIVITY: "daily-activity",
  INFRINGEMENT_LETTER: "infringement-letter",
};
export type ReportArchiveType = keyof typeof reportArchiveTypeValues;
interface ReportViewProps {
  permissions: UserPermissions;
}

interface QueryParams {
  [paramName: string]: any;
}

export const ReportView: React.FC<ReportViewProps> = ({ permissions }) => {
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const navigate = useNavigate();
  const [searchReport, setSearchReport] = useState("");
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const reportsMetadata = useAppSelector(reportsMetadataSelectors.selectAll);
  const schedules = useAppSelector(schedulesSelectors.selectAll);

  const numberOfScheduledExecutions = useAppSelector(
    selectReportArchiveStatusSlicTotalElements
  );
  useEffect(() => {
    if (preferencesContext.language) {
      const map = new Map();
      map.set("lang", preferencesContext.language);
      handleChanges(map);
    }
  }, [preferencesContext]);

  const filterSchedules = (id: number[]) => {
    return schedules.filter((schedule) => id.includes(schedule.id));
  };

  const handleChanges = (params: Map<string, string[] | string>): void => {
    let queryString = "";
    let executeQuery = false;
    if (params) {
      params.forEach((value, key) => {
        if (!!value && value.length > 0) {
          queryParams[key] = value;
        } else if (queryParams.hasOwnProperty(key)) {
          delete queryParams[key];
          executeQuery = true;
        }
      });
      queryString = getQueryString(queryParams);
      executeQuery = true;
    }
    // effettuare chiamata API con parametri in query string
    if (executeQuery) {
      store.dispatch(getReportsMetadataAsync(queryString));
    }
  };

  useEffect(() => {
    document.title = t("breadcrumbs.reports");
    store.dispatch(
      getAllScheduledReportAsync({
        queryParams: getQueryString({ page: 0, size: 10 }),
      })
    );
    return () => {
      store.dispatch(reportsMetadataEmptyState());
      store.dispatch(schedulesEmptyState());
    };
  }, []);

  //#region Navigation Modal
  const archiveFileRoute = useMatch("/reports/archive/:type");
  const [reportArchiveType, setReportArchiveType] = useState(
    {} as ReportArchiveType
  );

  useEffect(() => {
    if (!permissions.reports.read) {
      // TODO: For future ACL development
      // navigate("/dashboard/fleet-control");
      return;
    }
    if (archiveFileRoute !== null) {
      if (
        archiveFileRoute.params.type &&
        Object.values(reportArchiveTypeValues).includes(
          archiveFileRoute.params.type
        )
      ) {
        setReportArchiveType(archiveFileRoute.params.type as ReportArchiveType);
      }
    }
  }, [archiveFileRoute, permissions.reports.read]);
  //#endregion Navigation Modal

  return (
    <>
      {permissions.reports.read ? (
        <div className="report">
          <div className="search-reports">
            <Form>
              <SearchField
                id="search-reports"
                value={searchReport}
                placeholder={t("report.mainPage.searchReportPlaceholder")}
                onChange={(val) => {
                  const map = new Map();
                  map.set("searchKey", val ?? "");
                  handleChanges(map);
                  setSearchReport(val);
                }}
              />
            </Form>
            <div
              className="report-archive"
              onClick={() => {
                navigate("/reports/schedulation/archive");
              }}
            >
              <div className="report-archive-title-section">
                <IconFileReport size={14} color="#031832" />
                <span className="report-archive-title">
                  {t("report.archive.modal.title") +
                    " (" +
                    numberOfScheduledExecutions +
                    ")"}
                </span>
              </div>
              <div className="report-archive-subtitle-section">
                <span className="report-archive-subtitle">
                  {t("report.archive.modal.subtitleOne")}
                </span>
                <span className="report-archive-subtitle-two">
                  {t("report.archive.modal.subtitleTwo")}
                </span>
              </div>
            </div>
          </div>
          <div className="sections">
            <div className="section">
              {
                <Reports
                  title={t("report.mainPage.vehicles")}
                  icon={
                    <IconVehicle color="--global-colors-ink-dark" size={14} />
                  }
                >
                  {permissions.vehicles.read &&
                  reportsMetadata.filter(
                    (report) => report.group === "vehicles"
                  ).length > 0 ? (
                    reportsMetadata
                      .filter((report) => report.group === "vehicles")
                      .map((report) => {
                        return (
                          <Report
                            key={report.id}
                            id={report.id}
                            title={report.title}
                            description={report.description}
                            scheduleOptions={filterSchedules(
                              report.scheduledReports
                            )}
                            getReport={() => {
                              navigate(
                                `/reports/${getReportRoute(
                                  `${report.reportTypeEnum}`
                                )}`
                              );
                            }}
                            lang={preferencesContext.language}
                          />
                        );
                      })
                  ) : (
                    <Report
                      id={0}
                      title={t("report.mainPage.noReportsFound")}
                      description={""}
                      isReportFound={false}
                    />
                  )}
                </Reports>
              }
            </div>
            <div className="section">
              {
                <Reports
                  title={t("report.mainPage.drivers")}
                  icon={
                    <IconDrivers color="--global-colors-ink-dark" size={14} />
                  }
                >
                  {permissions.drivers.read &&
                  reportsMetadata.filter((report) => report.group === "drivers")
                    .length > 0 ? (
                    reportsMetadata
                      .filter((report) => report.group === "drivers")
                      .map((report) => {
                        return (
                          <Report
                            key={report.id}
                            id={report.id}
                            title={report.title}
                            description={report.description}
                            scheduleOptions={filterSchedules(
                              report.scheduledReports
                            )}
                            getReport={() => {
                              navigate(
                                `/reports/${getReportRoute(
                                  `${report.reportTypeEnum}`
                                )}`
                              );
                            }}
                            lang={preferencesContext.language}
                          />
                        );
                      })
                  ) : (
                    <Report
                      id={0}
                      title={t("report.mainPage.noReportsFound")}
                      description={""}
                      isReportFound={false}
                    />
                  )}
                </Reports>
              }

              {
                <Reports
                  title={t("report.mainPage.geofence")}
                  icon={
                    <IconGeofence color="--global-colors-ink-dark" size={14} />
                  }
                >
                  {permissions.geofences.read &&
                  reportsMetadata.filter(
                    (report) => report.group === "geofences"
                  ).length > 0 ? (
                    reportsMetadata
                      .filter((report) => report.group === "geofences")
                      ?.map((report) => {
                        return (
                          <Report
                            key={report.id}
                            id={report.id}
                            title={report.title}
                            description={report.description}
                            scheduleOptions={filterSchedules(
                              report.scheduledReports
                            )}
                            getReport={() => {
                              navigate(
                                `/reports/${getReportRoute(
                                  `${report.reportTypeEnum}`
                                )}`
                              );
                            }}
                            lang={preferencesContext.language}
                          />
                        );
                      })
                  ) : (
                    <Report
                      id={0}
                      title={t("report.mainPage.noReportsFound")}
                      description={""}
                      isReportFound={false}
                    />
                  )}
                </Reports>
              }
              {
                <Reports
                  title={t("report.mainPage.tachograph")}
                  icon={
                    <IconLicense color="--global-colors-ink-dark" size={14} />
                  }
                >
                  {permissions.vehicles.read &&
                  permissions.drivers.read &&
                  reportsMetadata.filter(
                    (report) => report.group === "tachograph"
                  ).length > 0 ? (
                    reportsMetadata
                      .filter((report) => report.group === "tachograph")
                      ?.map((report) => {
                        return (
                          <Report
                            key={report.id}
                            id={report.id}
                            title={report.title}
                            description={report.description}
                            scheduleOptions={filterSchedules(
                              report.scheduledReports
                            )}
                            getReport={() => {
                              navigate(
                                `/reports/${getReportRoute(
                                  `${report.reportTypeEnum}`
                                )}`
                              );
                            }}
                            lang={preferencesContext.language}
                          />
                        );
                      })
                  ) : (
                    <Report
                      id={0}
                      title={t("report.mainPage.noReportsFound")}
                      description={""}
                      isReportFound={false}
                    />
                  )}
                </Reports>
              }
            </div>
          </div>
        </div>
      ) : undefined}
      {reportArchiveType === reportArchiveTypeValues.DAILY_ACTIVITY ? (
        <FileArchiveModal
          open={reportArchiveType === reportArchiveTypeValues.DAILY_ACTIVITY}
          title={t("customModals.archive.tachograph.title")}
          desc={t("customModals.archive.tachograph.daily_activity")}
          tableSchema={[
            { id: 1, name: "vehicle_driver" },
            { id: 2, name: "download" },
            { id: 3, name: "generationDate" },
          ]}
          data={{
            selector: tachographFilesSelectors,
            status: selectTachographFilesSliceStatus,
            api: getTachographFilesActivityReportAsync,
            bucket: process.env.REACT_APP_AMAZON_S3_BUCKET_TACHOGRAPHFILE,
            valuePath: {
              fileName: "fileName",
              generationDate: "timestamp",
              path: "path",
            },
            queryParamsName: {
              date: ["startPeriod", "endPeriod"],
            },
          }}
          iconSection={<IconLicense color="currentColor" size={48} />}
          filesNotFoundTexts={{
            title: t("customModals.archive.tachograph.filesNotFound.title"),
            desc: t("customModals.archive.tachograph.filesNotFound.desc"),
          }}
          onClose={() => {
            setReportArchiveType({} as ReportArchiveType);
            navigate(`/reports`);
          }}
        />
      ) : undefined}
      {reportArchiveType === reportArchiveTypeValues.INFRINGEMENT_LETTER ? (
        <FileArchiveModal
          open={
            reportArchiveType === reportArchiveTypeValues.INFRINGEMENT_LETTER
          }
          title={t("customModals.archive.tachograph.title")}
          desc={t("customModals.archive.tachograph.infringement_letter")}
          tableSchema={[
            { id: 1, name: "vehicle_driver" },
            { id: 2, name: "download" },
            { id: 3, name: "generationDate" },
          ]}
          data={{
            selector: tachographFilesSelectors,
            status: selectTachographFilesSliceStatus,
            api: getTachographFilesInfringementLetterReportAsync,
            bucket: process.env.REACT_APP_AMAZON_S3_BUCKET_TACHOGRAPHFILE,
            valuePath: {
              fileName: "fileName",
              generationDate: "timestamp",
              path: "path",
            },
            queryParamsName: {
              date: ["startPeriod", "endPeriod"],
            },
          }}
          iconSection={<IconLicense color="currentColor" size={48} />}
          filesNotFoundTexts={{
            title: t("customModals.archive.tachograph.filesNotFound.title"),
            desc: t("customModals.archive.tachograph.filesNotFound.desc"),
          }}
          onClose={() => {
            setReportArchiveType({} as ReportArchiveType);
            navigate(`/reports`);
          }}
        />
      ) : undefined}
    </>
  );
};
